<template>
  <div class="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
    <!-- SLIDER PRODUCTS  -->
    <div class="flex justify-start items-center mb-3">
      <div class="flex items-center">
        <h1 class="text-2xl lg:text-2xl tracking-tight font-semibold sm:text-3xl ">
          <!-- <span class="mr-2 text-slate-500">All</span> -->
          <span :class="getDark ? 'text-white':'text-slate-800'">Paket Rating</span>
        </h1>
      </div>
      <div @click.prevent="viewAllProduct" :class="getDark ? 'bg-zinc-800 text-gray-300 hover:bg-orange-500 hover:text-zinc-800 hover:border-orange-900'
              :'bg-white text-gray-500 hover:bg-secondary hover:text-white'"
        class="hover:cursor-pointer items-center py-2 px-4 text-md ml-5 font-bold rounded-full border border-gray-400 hidden sm:block">
        view all
      </div>
    </div>
    <div class="relative">
      <swiper :slides-per-view="1" :space-between="50" :loop="loop" :centeredSlides="false" :breakpoints="{
              '640': {
                  slidesPerView: 2,
                  spaceBetween: 20,
              },
              '768': {
                  slidesPerView: 3,
                  spaceBetween: 40,
              },
              '1024': {
                  slidesPerView: 4,
                  spaceBetween: 20,
              },
              }" :modules="modules" @swiper="onSwiper" @slideChange="onSlideChange"
        :navigation="{ nextEl: '.nextArrow', prevEl: '.prevArrow'}">
        <swiper-slide v-for="(product, i) in getAllProducts" :key="i" @click.prevent="viewProduct(product.id)"
          class="group mt-2 mb-10">
          <!-- SingleProduct Component -->
          <app-card :product="{
              title: product.product_name,
              titleSize: 'text-[14px]',
              imgSize: 'h-[200px] md:h-[150px]',
              inital_price: product.inital_price,
              price: product.price,
              discount_one: product.discount_one,
              discount_two: product.discount_two,
              sub_category: product.sub_category,
              sub_categorySize: 'text-lg font-bold',
              slug: product.slug,
              img: product.image ? product.image : gambar,
              button: true,
              }"></app-card>
          <!-- SingleProduct Component END -->
        </swiper-slide>
      </swiper>
      <div class="parallax-slider-navigation absolute right-5 -top-12
          flex justify-end">
        <!-- style="display: flex; justify-content: space-between; padding: 1rem;"> -->
        <div class="nav-indicator prevArrow fa-2x hover:cursor-pointer bg-white border border-gray-400 ml-2 hover:bg-primary h-9 w-9
                  flex justify-center items-center rounded-full hover:text-white">
          <i class="fa-solid text-lg font-bold fa-chevron-left"></i>
        </div>
        <div class="nav-indicator nextArrow fa-2x hover:cursor-pointer bg-white border border-gray-400 ml-2 hover:bg-primary h-9 w-9
                  flex justify-center items-center rounded-full hover:text-white">
          <i class="fa-solid text-lg font-bold  fa-chevron-right"></i>
        </div>
      </div>
    </div>
    <!-- SLIDER PRODUCTS END -->
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  //https://swiperjs.com/
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide  } from 'swiper/vue';
  import {   Navigation } from "swiper";

  // Import Swiper styles
  import 'swiper/css';
  import "swiper/css/navigation";

  import AppCard from '../../components/product/CardSlider.vue';
  import Mixin from '@/mixin/index';

  export default {
    name: 'ProductSlider',
    mixins: [Mixin],
    components: {
        Swiper,
        SwiperSlide,
        AppCard,
    },
    data() {
      return {
        loop: true,
        modules: [Navigation],
        gambar: `${process.env.VUE_APP_IMAGE}/img/cover.jpg`,
      };
    },
    methods: {
      onSwiper(swiper){
          // console.log(swiper);
      },
      onSlideChange(){
          // console.log('slide change');
      },
      viewAllProduct() {
        this.$router.push({ name: 'products' });
      },
    },
    computed: {
        ...mapGetters({
            getAllProducts: 'modProducts/getAllProducts',
            getDark: 'getDark',
        }),
    },
  };
</script>