import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/auth/Login.vue';
import Reset from '../views/auth/Reset.vue';
import NewPassword from '../views/auth/NewPassword.vue';
import SignUp from '../views/auth/SignUp.vue';
import OTP from '../views/auth/Otp.vue';
import OTPRESET from '../views/auth/OtpReset.vue';
import HomeView from '../views/home/HomeView.vue';
import Page404 from '../views/Page404.vue';
import Contact from '../views/ContactUs.vue';
import AppProducts from '../views/Products.vue';
import ProductDetail from '../views/ProductDetail.vue';
import CheckOut from '@/components/CheckOut.vue';
import Profile from '../views/profile/Default.vue';
import Biodata from '../views/profile/Biodata.vue';
import Transaction from '../views/profile/Transaction.vue';
import Order from '../views/profile/Order.vue';
import Invoice from '../views/profile/Invoice.vue';
import store from '../store';
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

const routes = [
  
  {
    path: '/login', 
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  
  {
    path: '/form-reset-password', 
    name: 'form-reset-password',
    component: Reset,
    meta: {
      requiresAuth: false,
    },
    beforeEnter (to, from, next) {
      // console.log(to.query.key)
      next();
    }
  },
  
  {
    path: '/reset-password', 
    name: 'reset-password',
    component: NewPassword,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/sign-up',
    name: 'signUp',
    component: SignUp,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/otp',
    name: 'otp',
    component: OTP,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/otp-reset',
    name: 'otp-reset',
    component: OTPRESET,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/',  
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: false,
    },
    beforeEnter (to, from, next) { 
      var params = {
          limit: 8,
          skip: 0,
          filter_product_name: '',
          filter_tahun: '',
          filter_category: '',
          sort: 'tahun DESC',
      };
      store.dispatch('modProducts/setAllProducts', params);
      next();
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/profile',
    component: Profile,
    children: [
      {
        path: '',
        name: 'profile',
        component: Biodata,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/transaction',
        name: 'transaction',
        component: Transaction,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/order',
        name: 'order',
        component: Order,
        meta: {
          requiresAuth: true,
        },
      }, 
    ]
  },
  {
    path: '/invoice/:id',
    name: 'invoice',
    component: Invoice,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/products',
    name: 'products',
    component: AppProducts,
    meta: {
      requiresAuth: false,
    },
    beforeEnter (to, from, next) {
      const params = {
          limit: 8,
          skip: 0,
          filter_product_name: '',
          filter_tahun: '',
          filter_category: '',
          sort: 'created_at DESC',
      }; 
      store.dispatch('modProducts/setAllProducts', params);
      next();
    }
  },
  {
    path: '/product/:id',
    name: 'productDetail',
    component: ProductDetail,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/check-out',
    name: 'checkout',
    component: CheckOut,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:catchAll(.*)*',
    redirect: { name: 'home'},
  },
  {
      path: '/404',
      name: 'Page404',
      component: HomeView,
      meta: {
        requiresAuth: false,
      },
    },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: 'text-yellow-500',
  scrollBehavior() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      passive: true,
    });
  },
})

router.beforeEach((to,from, next) => {
  if (to.name === 'productDetail') {
    if (to.params.id) {
      store.dispatch('modProducts/setDetailProduct', to.params.id);
    }
  }
  //displayMenu
  const stoMenuDisplay = JSON.parse(localStorage.getItem('menuDisplay')); 
  localStorage.setItem('menuDisplay', stoMenuDisplay)

  let disMenu = stoMenuDisplay;
  if (to.name === 'login' || to.name === 'signUp'|| to.name === 'otp-reset' || to.name === 'form-reset-password' || to.name === 'reset-password' || to.name === 'otp') {
    disMenu = false
    store.dispatch('setDisplayMenu', disMenu);
    localStorage.setItem('menuDisplay', disMenu)
  } else {
    disMenu = true
    store.dispatch('setDisplayMenu', disMenu);
    localStorage.setItem('menuDisplay', disMenu)
  }

  const isAuthenticated = JSON.parse(localStorage.getItem('authenticate'));
  if (isAuthenticated) {
      if (isAuthenticated.AUTH_TOKEN) {
        if (!store.getters['getAuth']) {
          store.dispatch('setAuthenticate', localStorage.getItem('authenticate'));
        };
    };
  };
    
//   if (to.matched.length === 0) {
//     if (isAuth) {
//       next({ name: 'home' });
//       return;
//     } else {
//       next({
//           path: '/404'
//       });
//       return;
//     };
//   };

//   if (isAuth &&  to.matched[0].name === 'login') {
//     next({ name: 'home' })
//   }; 
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // console.log(to.matched.some(record => record.meta.requiresAuth))
    if (isAuthenticated) {
        if (isAuthenticated.AUTH_TOKEN == null) {
          next({ name: 'home' });
        } else {
          if (isAuthenticated.AUTH_TOKEN != null) {
            next();
            return;
          } else {
            next({ name: 'home' });
          };
        };  
    }else{
      next('/');
    }
//     // next()
  } else {
    next();
  };

//   // if (to.name !== 'Login' && !isAuthenticated) next({ name: 'login' })
//   // if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) next({ name: 'login' }) 
//   // else next()
});

export default router
