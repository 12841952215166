<template>
  <div class="px-4 pt-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
    <div class="grid grid-cols-5">
      <div class="col-span-5 md:col-span-3 pb-5 lg:p-0 row-span-1">
        <div class="text-center lg:text-left sm:py-0 lg:pt-5">
          <h1 :class="getDark ? 'text-zinc-300':'text-primary'"
            class="text-3xl lg:text-4xl tracking-tight font-bold font-serif sm:text-3xl md:text-3xl">
            <span class="block">{{ getContent.home.title }}</span>
            <span class="block text-yellow-600 xl:inline">{{ getContent.home.subtitle }}</span>
          </h1>
          <p class="mt-3 text-gray-400 sm:mt-5 sm:text-lg text-justify sm:max-w-xl sm:mx-auto md:mt-5 md:text-md lg:mx-0"
            v-html="getContent.home.content"></p>
        </div>
      </div>
      <div class="col-span-5 md:col-span-2 min-h-full">
        <div class="flex flex-wrap gap-6 h-full">
            <img :src="banner" class="imga" alt="Info Bank Data" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Jumbotron', 
  computed: {
    ...mapGetters({
      getDark: 'getDark',
      getContent: 'modContent/getContent',
      banner: "banner"
    })
  }
};
</script>

<style>
  .imga { 
  object-fit: cover;
}
</style>