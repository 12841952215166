<template>
  <!-- <div class="sm:py-5 lg:container mx-auto lg:w-10/12 px-5"> -->
  <div
    class="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8"
  >
    <div>
      <h1
        class="text-2xl lg:text-2xl tracking-tight font-semibold text-gray-600 sm:text-3xl"
      >
        <span :class="getDark ? 'text-white' : 'text-slate-800'">Recommended</span>
      </h1>
    </div>
    <div class="mx-auto">
      <div class="flex justify-center flex-wrap md:justify-start gap-8">
        <!-- Column -->
        <div
          class="my-1 lg:my-4 lg:w-[30%] md:w-[45%] lg:mb-3 mb-5 group"
          v-for="(product, i) in getNewProducts.slice(0, 3)"
          :key="i"
          @click.prevent="viewProduct(product.id)"
        >
          <!-- SingleProduct Component -->
          <app-card
            :product="{
              title: product.product_name,
              titleSize: 'text-xl',
              imgSize: 'h-[300px] md:h-[250px]',
              content: product.description,
              inital_price: product.inital_price,
              price: product.price,
              discount_one: product.discount_one,
              discount_two: product.discount_two,
              sub_category: product.sub_category,
              sub_categorySize: 'text-lg font-bold',
              slug: product.slug,
              img: product.image ? product.image : gambar,
              button: true,
            }"
          ></app-card>
          <!-- SingleProduct Component END -->
        </div>
        <!-- END Column -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AppCard from "../../components/product/CardRecomended.vue";
import Mixin from "@/mixin/index";
export default {
  name: "Recommended",
  mixins: [Mixin],
  components: {
    AppCard,
  },
  data() {
    return {
      gambar: `${process.env.VUE_APP_IMAGE}/img/cover.jpg`,
    };
  },
  computed: {
    ...mapGetters({
      getNewProducts: "modProducts/getNewProducts",
      getDark: "getDark",
    }),
  },
};
</script>
<style></style>
