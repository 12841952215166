import axios from 'axios';

export default {
  namespaced: true,
  state: {
    company: {
      phone: "",
      email: "",
      address: "",
      sosmed: {
        facebook: "",
        twitter: "",
        youtube: "",
        instagram: "",
      },
    },
    content: {
      home: {
        title: "",
        subtitle: "",
        content: "",
        about: "",
        visi: "",
        misi: "",
      },
      footer: {
        title: "",
        subtitle: "",
        content: "",
      },
    },
  },
  getters: {
    getCompany: (state) => {
      return state.company;
    },
    getContent: (state) => {
      return state.content;
    },
  },
  mutations: {
    UPDATE_TITLE_HOME(state, data) {
      state.content.home.title = data;
    },
    UPDATE_SUBTITLE_HOME(state, data) {
      state.content.home.subtitle = data;
    },
    UPDATE_ABOUT(state, data) {
      state.content.home.about = data;
    },
    UPDATE_VISI(state, data) {
      state.content.home.visi = data;
    },
    UPDATE_MISI(state, data) {
      state.content.home.misi = data;
    },
    UPDATE_CONTENT_HOME(state, data) {
      state.content.home.content = data;
    },
    UPDATE_CONTENT_FOOTER(state, data) {
      state.content.footer.content = data;
    },
    UPDATE_PHONE_COMPANY(state, data) {
      state.company.phone = data;
    },
    UPDATE_EMAIL_COMPANY(state, data) {
      state.company.email = data;
    },
    UPDATE_ALAMAT_COMPANY(state, data) {
      state.company.address = data;
    },
  },
  actions: {
    async setFooterContent({ commit }) {
      try {
        var config = {
          method: "get",
          url: `${process.env.VUE_APP_LOCAL}/v1/content/footer/content`,
        };
        await axios(config)
          .then((res) => {
            commit("UPDATE_CONTENT_FOOTER", res.data.result.value);
          })
          .catch((e) => console.log(e));
      } catch (error) {
        console.log("terjadi kesalahan server");
      }
    },

    async setAlamatCompany({ commit }) {
      try {
        var config = {
          method: "get",
          url: `${process.env.VUE_APP_LOCAL}/v1/content/contact/alamat`,
        };
        await axios(config)
          .then((res) => {
            commit("UPDATE_ALAMAT_COMPANY", res.data.result.value);
          })
          .catch((e) => console.log(e));
      } catch (error) {
        console.log("terjadi kesalahan server");
      }
    },

    async setEmailCompany({ commit }) {
      try {
        var config = {
          method: "get",
          url: `${process.env.VUE_APP_LOCAL}/v1/content/contact/email`,
        };
        await axios(config)
          .then((res) => {
            commit("UPDATE_EMAIL_COMPANY", res.data.result.value);
          })
          .catch((e) => console.log(e));
      } catch (error) {
        console.log("terjadi kesalahan server");
      }
    },

    async setPhoneCompany({ commit }) {
      try {
        var config = {
          method: "get",
          url: `${process.env.VUE_APP_LOCAL}/v1/content/contact/phone`,
        };
        await axios(config)
          .then((res) => {
            commit("UPDATE_PHONE_COMPANY", res.data.result.value);
          })
          .catch((e) => console.log(e));
      } catch (error) {
        console.log("terjadi kesalahan server");
      }
    },

    async setTitleHome({ commit }) {
      try {
        var config = {
          method: "get",
          url: `${process.env.VUE_APP_LOCAL}/v1/content/home/title`,
        };
        await axios(config)
          .then((res) => {
            commit("UPDATE_TITLE_HOME", res.data.result.value);
          })
          .catch((e) => console.log(e));
      } catch (error) {
        console.log("terjadi kesalahan server");
      }
    },

    async setSubTitleHome({ commit }) {
      try {
        var config = {
          method: "get",
          url: `${process.env.VUE_APP_LOCAL}/v1/content/home/subtitle`,
        };
        await axios(config)
          .then((res) => {
            commit("UPDATE_SUBTITLE_HOME", res.data.result.value);
          })
          .catch((e) => console.log(e));
      } catch (error) {
        console.log("terjadi kesalahan server");
      }
    },

    async setAbout({ commit }) {
      try {
        var config = {
          method: "get",
          url: `${process.env.VUE_APP_LOCAL}/v1/content/about-us/content`,
        };
        await axios(config)
          .then((res) => {
            // console.log(res.data.result.value)
            // console.log('++++')
            commit("UPDATE_ABOUT", res.data.result.value);
          })
          .catch((e) => console.log(e));
      } catch (error) {
        console.log("terjadi kesalahan server");
      }
    },

    async setVisi({ commit }) {
      try {
        var config = {
          method: "get",
          url: `${process.env.VUE_APP_LOCAL}/v1/content/about-us/visi`,
        };
        await axios(config)
          .then((res) => {
            commit("UPDATE_VISI", res.data.result.value);
          })
          .catch((e) => console.log(e));
      } catch (error) {
        console.log("terjadi kesalahan server");
      }
    },

    async setMisi({ commit }) {
      try {
        var config = {
          method: "get",
          url: `${process.env.VUE_APP_LOCAL}/v1/content/about-us/misi`,
        };
        await axios(config)
          .then((res) => {
            commit("UPDATE_MISI", res.data.result.value);
          })
          .catch((e) => console.log(e));
      } catch (error) {
        console.log("terjadi kesalahan server");
      }
    },

    async setContentHome({ commit }) {
      try {
        var config = {
          method: "get",
          url: `${process.env.VUE_APP_LOCAL}/v1/content/home/content`,
        };
        await axios(config)
          .then((res) => {
            commit("UPDATE_CONTENT_HOME", res.data.result.value);
          })
          .catch((e) => console.log(e));
      } catch (error) {
        console.log("terjadi kesalahan server");
      }
    },
  },
};