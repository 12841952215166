<template>
  <div :class="getDark ? 'bg-zinc-800' : 'bg-zinc-200'" class="h-screen w-full">
    <div class="pt-[20vh] h-full w-full z-20">
      <div
        class="flex max-w-sm mx-auto overflow-hidden rounded-lg shadow-none sm:shadow-lg lg:max-w-lg bg-transparent sm:bg-white"
      >
        <!-- right  -->
        <div class="w-full px-6 py-8 md:px-8">
          <!-- Title  -->
          <div class="w-full flex justify-center">
            <router-link
              :to="{ name: 'home' }"
              class="text-5xl cursor-pointer font-semibold"
            >
              <img class="h-8 w-auto sm:h-[70px] cursor-pointer" :src="logo" alt="" />
            </router-link>
          </div>
          <div class="flex items-center justify-between mt-4">
            <span class="w-1/5 border-b dark:border-gray-400 lg:w-1/4"></span>
            <div class="text-xs text-center text-secondary uppercase dark:text-gray-400">
              New Password
            </div>
            <span class="w-1/5 border-b dark:border-gray-400 lg:w-1/4"></span>
          </div>
          <!-- Title End  -->
          <!-- Form  -->
          <vee-form @submit="newPass" :validation-schema="NewPassSchema">
            <div class="mt-4">
              <global-input
                v-model="password"
                label="New Password"
                type="password"
                nama="password"
                class="outline-none focus:border focus:border-zinc-400 bg-zinc-200"
              ></global-input>
            </div>
            <!-- <a href="javascript:void(0)" @click.prevent="otp" class="text-xs text-gray-500 dark:text-gray-300 hover:underline">OTP</a> -->
            <div class="mt-8">
              <button
                class="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-gray-700 rounded hover:bg-primary focus:outline-none focus:bg-primary"
                v-if="loadingSpinner"
              >
                <global-loading></global-loading>
              </button>
              <button
                v-else
                class="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-gray-700 rounded hover:bg-primary focus:outline-none focus:bg-primary hover:cursor-pointer"
              >
                Submit
              </button>
            </div>
          </vee-form>
          <!-- Form End  -->
          <div v-if="!getAuth" class="flex items-center justify-between mt-4">
            <span class="w-1/5 border-b dark:border-primary md:w-1/4"></span>
            <div
              @click="signup"
              class="text-xs text-gray-500 uppercase dark:text-gray-400 hover:cursor-pointer"
            >
              Login
            </div>
            <span class="w-1/5 border-b dark:border-primary md:w-1/4"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import GlobalInput from "@/components/GlobalInput.vue";
import GlobalLoading from "@/components/GlobalLoading.vue";
import Alert from "@/mixin";
import { useRoute } from "vue-router";

export default {
  name: "newPassword",
  components: {
    GlobalInput,
    GlobalLoading,
  },
  mixins: [Alert],
  data() {
    return {
      token: null,
      NewPassSchema: {
        password: "required|min:5",
      },
      loadingSpinner: false,
      password: "",
      titleApp: process.env.VUE_APP_NAMES,
      warning: {
        message: "Data Invalid, please try again",
        color: "bg-red-300",
      },
      token: null,
    };
  },
  created() {
    const route = useRoute();
    this.token = route.query.ib;
  },
  methods: {
    goTo() {
      this.$router.push({ name: "home" });
    },
    signup() {
      this.$router.push({ name: "signUp" });
    },
    resetP() {
      this.$router.push({ name: "reset-password" });
    },
    otp() {
      this.$router.push({ name: "otp" });
    },
    getAvatarName(name) {
      const words = name.split(" ");
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      const nama = words.join(" ");
      return nama;
    },
    async newPass() {
      this.loadingSpinner = true;
      const data = {
        password: this.password,
      };
      // this.showAlert('success', 'Create New Password Successfully');

      // setTimeout(() => {

      //     this.$router.push({ name: 'login' });
      // }, 3000);

      // const config = {
      // 	headers: {
      // 		'Content-type': 'application/json',
      // 	},
      // 	withCredentials: true,
      // };
      try {
        // const res = await axios.post(`${process.env.VUE_APP_LOCAL}/v1/change/password`, data, config)

        var config = {
          method: "post",
          url: `${process.env.VUE_APP_LOCAL}/v1/change/password`,
          data: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        };
        await axios(config)
          .then((res) => {
            const payload = res.data;
            if (res.data.result) {
              this.showAlert("success", "New Password Has Been Change");
              setTimeout(() => {
                this.loadingSpinner = false;
                this.$router.push({ name: "login" });
              }, 2000);
            } else {
              this.loadingSpinner = false;
              this.showAlert("error", "Unauthorized");
            }
          })
          .catch((err) => {
            this.loadingSpinner = false;
            this.showAlert("error", "Unauthorized");
          });
      } catch (error) {
        this.loadingSpinner = false;
        this.showAlert("error", "Network Error");
      }
    },
  },
  computed: {
    ...mapGetters({
      getDark: "getDark",
      getAuth: "getAuth",
      logo: "logo",
    }),
  },
};
</script>

<style scoped>
.slide-fade-enter-from {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 1s ease 0.3s;
}

.slide-fade-enter-to {
  opacity: 1;
}

/* .slide-fade-leave-from {
		opacity: 1;
	} */
.slide-fade-leave-active {
  transition: opacity 1s;
}

.slide-fade-leave-to {
  opacity: 0;
}
</style>
