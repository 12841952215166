import axios from 'axios';

export default {
    namespaced: true,
    state: {
      category: [],
      categoryPaketRating: [],
      categoryKinerjaKeuangan: [],
    },
    getters: {
      getCategory: (state) => {
          return state.category;
      },
      getCategoryPaketRating: (state) => {
          return state.categoryPaketRating;
      },
      getKinerjaKeuangan: (state) => {
          return state.categoryKinerjaKeuangan;
      },
    },
    mutations: {
        UPDATE_CATEGORY(state, data) {
          data.map((e) => {
            if(e.category === 'Paket Rating') {
                state.categoryPaketRating = e;
            } else{
              state.categoryKinerjaKeuangan = e;
            } 
          }) 
        },
    },
    actions: {
        async setCategory({ commit }) {
            try {
              var config = {
                method: 'get',
                url: `${process.env.VUE_APP_LOCAL}/v1/sub/category`,
              };
              await axios(config)
       
              .then((res) => { 
                const qryCategory = []; 
                for (let i = 0; i < res.data.result.length; i++) { 
                  qryCategory.push(res.data.result[i])
                  // console.log(res.data.result[i])
                  // for (let no = 0; no < res.data.result[i]['category_sub'].length; no++) {
                  //   qryCategory.push({
                  //     id_category: res.data.result[i]['id'],
                  //     name_category: res.data.result[i]['category'],
                  //     id_sub_category: res.data.result[i]['category_sub'][no]['id'],
                  //     sub_category: res.data.result[i]['category_sub'][no]['sub_category']
                  //   });
                  // } 
                }; 
                commit('UPDATE_CATEGORY', qryCategory);
              })
              .catch((e) => console.log(e));
            } catch (error) {
              console.log('terjadi kesalahan server');
            };
        },
    }

}