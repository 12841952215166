import { createStore } from 'vuex';
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import router from '../router';
import axios from 'axios';

import modProducts from './modules/products';
import modTransaksi from './modules/transaksi';
import modKategori from './modules/category';
import modProfile from './modules/profile';
import modCart from './modules/cart';
import modMenu from './modules/menu';
import modContent from './modules/content';

function updateLocalStorage(cart) {
  localStorage.setItem('cart', JSON.stringify(cart));
}

export default createStore({
  state: {
    darkTheme: false,
    showAlert: {
      status: false,
      message: "hallo",
      color: "red",
    },
    product: [],
    category: [],
    subCategory: [],
    cart: [],
    detailProduct: [],
    productSearch: [],
    tahunProduct: [
      "2022",
      "2021",
      "2020",
      "2019",
      "2018",
      "2017",
      "2016",
      "2015",
      "2014",
      "2013",
      "2012"
    ],
    isAuth: false,
    authenticate: [],
    // listFaq: [
    //   {
    //     id: 1,
    //     title: "Popular articles",
    //     body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Malesuada proin libero nunc consequat interdum varius sit amet. Volutpat consequat mauris nunc congue.",
    //   },
    //   {
    //     id: 2,
    //     title: "Fix problems & request removals",
    //     body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Malesuada proin libero nunc consequat interdum varius sit amet. Volutpat consequat mauris nunc congue.",
    //   },
    //   {
    //     id: 3,
    //     title: "Browse the web",
    //     body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Malesuada proin libero nunc consequat interdum varius sit amet. Volutpat consequat mauris nunc congue.",
    //   },
    //   {
    //     id: 4,
    //     title: "Search on your phone or tablet",
    //     body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Malesuada proin libero nunc consequat interdum varius sit amet. Volutpat consequat mauris nunc congue.",
    //   },
    // ],
    showPayment: {
      show: false,
      link: null
    },
    displayMenu: true,
    logo: null,
    banner: null,
  },
  getters: {
    logo: (state) => state.logo,
    banner: (state) => state.banner,
    displayMenu: (state) => state.displayMenu,
    showPayment: (state) => state.showPayment,
    getDark: (state) => state.darkTheme,
    getTahunProduct: (state) => state.tahunProduct,
    getShowAlert: (state) => state.showAlert,
    getAuthenticate: (state) => state.authenticate[0],
    getAuth: (state) => state.isAuth,
    getProducts: (state) => state.product,
    // getFaq: (state) => state.listFaq,
    productQuantity: (state) => (product) => {
      const tot = state.cart.length;
      if (tot > 0) {
        const cart = JSON.parse(JSON.stringify(state.cart));
        const item = cart.find((i) => i.id === product);
        if (item) {
          return 1;
        }
        return 0;
      }
      return 0;
    },
    getAllCart: (state) => state.cart,
    getDetailProduct: (state) => state.detailProduct,
    getCategory: (state) => state.category,
    getSubCategory: (state) => state.subCategory,
    getProductSearch: (state) => state.productSearch,
  },
  mutations: {
    SET_LOGO(state, data) {   
      state.logo =  data; 
    },
    SET_BANNER(state, data) {   
      state.banner =  data; 
    },
    updateDisplayMenu(state, data) {   
      state.displayMenu =  data; 
    },
    update_payment(state, data) {
      if (data) {
        state.showPayment.show = true;
        state.showPayment.link = data;
      } else {
        state.showPayment.show = false;
        state.showPayment.link = null;
      }
    },
    UPDATE_DARK(state) {
      state.darkTheme = !state.darkTheme;
    },
    updateShowAlert(state, data) {
      state.showAlert = {
        status: !state.showAlert.status,
        message: data.message,
        color: data.color,
      };
    },
    updateAuthenticate(state, data) {
      if (data) { 
        state.authenticate = [];
        localStorage.setItem("authenticate", data);
        state.isAuth = true;
        state.authenticate.push(JSON.parse(data));
      } else {
        state.isAuth = false;
        state.authenticate = [];
      }
    },
    updateAuth(state, data) {
      state.isAuth = data;
    },
    updateProduct(state, data) {
      state.product = data;
    },
    updateCategory(state, data) {
      state.category = data;
    },
    updateSubCategory(state, data) {
      state.subCategory = data;
    },
    updateCartFromLocal(state) {
      const locStor = localStorage.getItem("cart");
      if (locStor != null) {
        state.cart = JSON.parse(locStor);
      }
    },
    updateCart(state, data) {
      if (state.cart.length > 0) {
        const getData = state.cart.find((i) => i.id === data.id);
        if (getData) {
          getData.qty = data.qty;
          getData.quantity = data.qty;
        } else {
          state.cart.push({ ...data, quantity: data.qty });
        }
      } else {
        state.cart.push({ ...data, quantity: data.qty });
      }
      updateLocalStorage(state.cart);
    },
    deleteCart(state, data) {
      state.cart = state.cart.filter((i) => i.id !== data);
      updateLocalStorage(state.cart);
    },
    updateDetailProduct(state, data) {
      state.detailProduct = data;
    },
    updateProductSearch(state, data) {
      state.productSearch = data;
    },
  },
  actions: {
    setDisplayMenu({ commit }, data) {
      commit("updateDisplayMenu", data);
    },
    showPayment({ commit }, data) {
      commit("update_payment", data);
    },
    setDarkMode({ commit }) {
      commit("UPDATE_DARK");
    },
    setAuthenticate({ commit }, data) {
      commit("updateAuthenticate", data);
    },

    setShowAlert({ commit }, data) {
      commit("updateShowAlert", data);
    },
    setAvatar({ commit }, data) {
      commit("updateAvatar", data);
    },
    setAuth({ commit }, data) {
      commit("updateAuth", data);
    }, 
    

    async fetchLogo({ commit }) {
      try { 
        var config = {
          method: "get",
          url: `${process.env.VUE_APP_LOCAL}/v1/image/logo`,
        }; 
        await axios(config)
          .then((res) => {  
            commit("SET_LOGO", res.data.result.image_url);
          })
          .catch((e) => console.log(e));
      } catch (error) {
        console.log("terjadi kesalahan server");
      }
    },
    async fetchBanner({ commit }) {
      try { 
        var config = {
          method: "get",
          url: `${process.env.VUE_APP_LOCAL}/v1/image/banner`,
        }; 
        await axios(config)
          .then((res) => {  
            commit("SET_BANNER", res.data.result.image_url);
          })
          .catch((e) => console.log(e));
      } catch (error) {
        console.log("terjadi kesalahan server");
      }
    },

    logout(context, reqiuresAuth) {
      localStorage.removeItem("isAuth");
      localStorage.removeItem("authenticate");
      localStorage.removeItem("products");
      localStorage.removeItem("cart");
      localStorage.removeItem("totWaitPay");
      localStorage.removeItem("totProd");
      localStorage.removeItem("totOrder"); 
      localStorage.setItem('menuDisplay', true)
      cookies.remove("authenticate");
      context.dispatch("setAuthenticate", null);
      context.dispatch("modCart/logoutCart");
      if (reqiuresAuth) {
        router.push({ name: "home" });
      }
    },
  },
  modules: {
    modProducts,
    modTransaksi,
    modKategori,
    modCart,
    modMenu,
    modProfile,
    modContent,
  },
});
