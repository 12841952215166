<template>
  <div class="w-full">
    <div
      class="px-4 pt-5 lg:pt-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8"
    >
      <div :class="getDark ? 'border-b border-zinc-700' : ''" class="relative pb-14 mb-8">
        <!-- component top 1  -->
        <div class="flex justify-between items-center space-y-2 pb-10">
          <!-- breadchumb  -->
          <div
            class="text-[11px] sm:text-sm leading-normal sm:leading-4 items-center dark:text-white"
          >
            <span
              :class="getDark ? 'text-zinc-400 hover:text-zinc-200' : 'text-gray-600'"
              class="hover:cursor-pointer"
            >
              Products
            </span>
            <i
              :class="getDark ? 'text-zinc-100' : 'text-gray-800'"
              class="fa-solid fa-chevron-right px-2 -pt-2"
            ></i>
            <span
              :class="getDark ? 'text-zinc-400 hover:text-zinc-200' : 'text-gray-600'"
              class="hover:cursor-pointer"
            >
              {{ getSingleProducts.sub_category }}
            </span>
            <i
              :class="getDark ? 'text-zinc-100' : 'text-gray-900'"
              class="fa-solid fa-chevron-right px-2 -pt-2"
            ></i>
            <span
              :class="getDark ? 'text-zinc-400 hover:text-zinc-200' : 'text-gray-600'"
              class="font-bold"
            >
              {{ getSingleProducts.product_name }}
            </span>
          </div>
        </div>

        <div
          class="md:grid md:grid-flow-row-dense md:grid-cols-3 md:gap-4 md:items-start"
        >
          <!-- left section  -->
          <div class="relative mb-5 lg:mb-0">
            <!-- button back  -->
            <div
              @click.prevent="goBack"
              title="go back"
              class="z-30 w-8 h-8 bg-slate-200 p-2 rounded-full items-center flex justify-center absolute top-3 left-3 cursor-pointer hover:bg-yellow-500 duration-500 transition-all ease-in-out"
            >
              <i class="fa-solid fa-chevron-left font-bold"></i>
            </div>
            <!-- images  -->
            <div
              class="mx-0 lg:mx-0 col-start-1 w-full px-16 flex justify-center items-center"
            >
              <img
                :src="getSingleProducts.image ? getSingleProducts.image : gambar"
                alt="illustration"
                class="w-full md:w-full sm:w-8/12 rounded-md shadow-lg h-auto"
              />
              <!-- <img :src="getSingleProducts.image" alt="illustration" class="w-full md:w-full sm:w-8/12 rounded-md shadow-lg h-auto"/> -->
            </div>
          </div>
          <!-- left section end -->

          <!-- right section  -->
          <div class="col-span-2 xl:pl-20 lg:pl-10">
            <h6
              class="text-sm lg:text-md leading-8 font-bold text-orange-400 dark:text-white tracking-tight sm:leading-9"
            >
              {{ getSingleProducts.sub_category }}
            </h6>
            <h4
              :class="getDark ? 'text-zinc-400 hover:text-zinc-200' : 'text-gray-900'"
              class="text-2xl md:text-2xl lg:text-2xl xl:text-3xl leading-8 dark:text-white tracking-tight sm:leading-9"
            >
              {{ getSingleProducts.product_name }}
            </h4>
            <!-- rating  -->
            <div
              :class="getDark ? 'border-zinc-700' : 'border-zinc-200'"
              class="w-full hover:cursor-pointer mb-2 pb-8 border-b"
            >
              <div class="flex items-center" v-if="getSingleProducts.discount_one > 0">
                <div class="items-center py-2 px-5 rounded-full bg-red-200">
                  <span
                    :class="
                      getDark ? 'text-zinc-400 hover:text-zinc-200' : 'text-gray-900'
                    "
                    class="ml-2 text-md font-bold"
                  >
                    Discount
                    {{
                      getSingleProducts.discount_one
                        ? `${getSingleProducts.discount_one}%`
                        : ""
                    }}
                    {{
                      getSingleProducts.discount_two
                        ? ` + ${getSingleProducts.discount_two}%`
                        : ""
                    }}
                  </span>
                </div>
                <!-- <span class="text-sm font-medium text-gray-500 no-underline hover:no-underline dark:text-white">
                  {{ getSingleProducts.count ? getSingleProducts.count : 1 }} views
                </span> -->
              </div>
            </div>

            <!-- price product  -->
            <div
              v-if="getSingleProducts.discount_one > 0"
              class="w-full px-4 sm:px-0 py-1"
            >
              <span
                :class="getDark ? 'text-red-300' : 'text-red-300'"
                class="flex-col text-2xl md:text-xl line-through"
              >
                {{ formatRupiah(getSingleProducts.inital_price) }}
              </span>
            </div>
            <div class="w-full px-4 sm:px-0 py-5">
              <span
                :class="
                  getDark
                    ? 'text-blue-300'
                    : getSingleProducts.price > 0
                    ? 'text-blue-900'
                    : 'text-red-500'
                "
                class="flex-col font-bold text-2xl md:text-2xl lg:text-2xl xl:text-3xl"
              >
                {{
                  getSingleProducts.price > 0
                    ? formatRupiah(getSingleProducts.price)
                    : "FREE"
                }}
              </span>
            </div>
            <div class="w-full px-4 sm:px-0 custom">
              <p
                :class="getDark ? 'text-zinc-400' : 'text-zinc-700'"
                class="py-6"
                v-html="getSingleProducts.description"
                maxChars="100"
              ></p>
            </div>

            <!-- btn loading  -->
            <div class="flex px-4 sm:px-0" v-if="loadingSpinner">
              <div
                class="flex items-center justify-center h-12 px-4 rounded-md bg-primary text-white ease-in-out duration-300"
              >
                <svg
                  class="w-5 h-5 text-white animate-spin left-1/2 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    fill="currentColor"
                  />
                </svg>
                <span class="font-bold">Loading ...</span>
              </div>
            </div>

            <div class="flex px-4 sm:px-0" v-else>
              <div
                @click.prevent="addCart(getSingleProducts)"
                class="flex items-center bg-primary text-white hover:bg-yellow-500 hover:text-slate-800 justify-center h-12 px-4 rounded-md ease-in-out duration-300 hover:cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
                <span class="font-semibold">Add to cart</span>
              </div>
            </div>
            <!-- btn loading end -->
          </div>
          <!-- right section end -->
        </div>
      </div>
      <!-- PRODUCT DETAIL END-->
    </div>

    <!-- other products  -->
    <similiar-products></similiar-products>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SimiliarProducts from "@/components/product/SimiliarProducts.vue";
import Mixin from "@/mixin/index.js";

export default {
  name: "ProductDetail",
  components: {
    SimiliarProducts,
  },
  mixins: [Mixin],
  data() {
    return {
      gambar: `${process.env.VUE_APP_IMAGE}/img/cover.jpg`,
      price: 5000,
      qty: 1,
      totalBelanja: 0,
      loadingSpinner: false,
    };
  },
  created() {
    this.showLoading();
  },
  methods: {
    goBack() {
      this.$router.back(-1);
    },
    addCart(val) {
      const data = {
        id_category: "2",
        id_category_sub: val.id_category_sub.toString(),
        id_product: val.id.toString(),
      };
      if (this.getAuth) {
        this.loadingSpinner = true;
        setTimeout(() => {
          this.$store.dispatch("modCart/addCart", data);
          this.showAlert("success", "Item has been add to cart");
          this.$router.push({ name: "checkout" });
          this.loadingSpinner = false;
        }, 2000);
      } else {
        this.loadingSpinner = true;
        setTimeout(() => {
          this.loadingSpinner = false;
          this.$swal
            .fire({
              // title: '',
              text: "Please Login for Authentication",
              // icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: "#034078",
              cancelButtonColor: "#d33",
              confirmButtonText: "Login",
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.loader();
                this.$router.push({ name: "login" });
              }
            });
        }, 2000);
      }
    },
  },
  computed: {
    ...mapGetters({
      getSingleProducts: "modProducts/getSingleProducts",
      getAuth: "getAuth",
      getDark: "getDark",
    }),
  },
};
</script>
