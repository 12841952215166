<template>
  <div :class="getDark ? 'bg-zinc-800' : 'bg-zinc-200'" class="h-screen w-full">
    <div class="pt-[20vh] h-full w-full z-20">
      <div
        class="flex max-w-sm mx-auto overflow-hidden rounded-lg shadow-none sm:shadow-lg lg:max-w-lg bg-transparent sm:bg-white"
      >
        <!-- left  -->
        <!-- <div @click="goTo" v-tooltip="{ content: () => 'Back to home', html:true }"
					class="hidden cursor-pointer lg:block lg:w-1/2 bg-contain bg-no-repeat bg-center"
					style="background-image:url('https://blog.mediaant.id/wp-content/uploads/2018/09/feature-5.jpg')">
				</div> -->
        <!-- right  -->
        <!-- :class="getDark ? 'bg-transparent sm:bg-zinc-900 text-zinc-200':'bg-transparent sm:bg-zinc-300 text-primary'" -->

        <div class="w-full px-6 py-8 md:px-8">
          <!-- Title  -->
          <div class="w-full flex justify-center">
            <router-link
              :to="{ name: 'home' }"
              class="text-5xl cursor-pointer font-semibold"
            >
              <img class="h-8 w-auto sm:h-[70px] cursor-pointer" :src="logo" alt="" />
            </router-link>
          </div>
          <div class="flex items-center justify-between mt-4">
            <span class="w-1/5 border-b dark:border-gray-400 lg:w-1/4"></span>
            <div
              v-if="getAuth"
              class="text-xs text-center text-secondary uppercase dark:text-gray-400"
            >
              Ubah Password
            </div>
            <div
              v-else
              class="text-xs text-center text-secondary uppercase dark:text-gray-400"
            >
              Lupa Password
            </div>
            <span class="w-1/5 border-b dark:border-gray-400 lg:w-1/4"></span>
          </div>
          <!-- Title End  -->
          <!-- Form  -->
          <vee-form @submit="resetPasswords" :validation-schema="loginSchema">
            <div class="mt-4">
              <global-input
                v-model="email"
                label="Email"
                type="email"
                nama="email"
                class="outline-none focus:border focus:border-zinc-400 bg-zinc-200"
              ></global-input>
            </div>
            <div v-if="!getAuth" class="mt-8">
              <button
                class="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-gray-700 rounded hover:bg-primary focus:outline-none focus:bg-primary"
                v-if="loadingSpinner"
              >
                <global-loading></global-loading>
              </button>
              <button
                v-else
                class="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-gray-700 rounded hover:bg-primary focus:outline-none focus:bg-primary hover:cursor-pointer"
              >
                Reset password
              </button>
            </div>
            <div v-else class="mt-8">
              <button
                class="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-gray-700 rounded hover:bg-primary focus:outline-none focus:bg-primary"
                v-if="loadingSpinner"
              >
                <global-loading></global-loading>
              </button>
              <button
                v-else
                class="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-gray-700 rounded hover:bg-primary focus:outline-none focus:bg-primary hover:cursor-pointer"
              >
                Ubah Password
              </button>
            </div>
          </vee-form>
          <!-- Form End  -->
          <div v-if="!getAuth" class="flex items-center justify-between mt-4">
            <span class="w-1/5 border-b dark:border-primary md:w-1/4"></span>
            <div
              @click="login"
              class="text-xs text-gray-500 uppercase dark:text-gray-400 hover:cursor-pointer"
            >
              Login
            </div>
            <span class="w-1/5 border-b dark:border-primary md:w-1/4"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import GlobalInput from "@/components/GlobalInput.vue";
import GlobalLoading from "@/components/GlobalLoading.vue";
import Alert from "@/mixin";

export default {
  name: "resetPassword",
  components: {
    GlobalInput,
    GlobalLoading,
  },
  mixins: [Alert],
  data() {
    return {
      loginSchema: {
        email: "required",
      },
      loadingSpinner: false,
      email: null,
      titleApp: process.env.VUE_APP_NAMES,
    };
  },
  methods: {
    goTo() {
      this.$router.push({ name: "home" });
    },
    login() {
      this.$router.push({ name: "login" });
    },
    getAvatarName(name) {
      const words = name.split(" ");
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      const nama = words.join(" ");
      return nama;
    },
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    async resetPasswords() {
      this.loadingSpinner = true;
      const data = {
        email: this.email,
        // 'password' : this.password,
      };
      console.log(data);
      const cek = this.validateEmail(this.email)
      console.log(cek)

      // Reset Password

      // const config = {
      // 	headers: {
      // 		'Content-type': 'application/json',
      // 	},
      // 	withCredentials: true,
      // };
      // if (cek) {
      // 	try {
      // 		const res = await axios.post(`${process.env.VUE_APP_LOCAL}/v1/reset/password`, data)
      // 			.then(res => {
      // 				// const payload = res.data;
      // 				// if (payload.result.token_access['x-token']) {
      // 				// 	const avatar = `https://ui-avatars.com/api/?name=${this.getAvatarName(payload.result.fullname)}`;
      // 				// 	const profile = {
      // 				// 		'email': payload.result.email,
      // 				// 		'fullname': payload.result.fullname,
      // 				// 		'id': payload.result.id,
      // 				// 		'phone': payload.resultphone,
      // 				// 	};
      // 				// 	const arrLogin = {
      // 				// 		'AUTH_TOKEN': payload.result.token_access['x-token'],
      // 				// 		'profile': profile,
      // 				// 		'avatar': avatar,
      // 				// 	};

      // 				setTimeout(() => {
      // 					this.email = null;
      // 					this.loadingSpinner = false;
      // 					// 		this.$store.dispatch('setAuthenticate', JSON.stringify(arrLogin));
      // 					// 		this.$store.dispatch('modTransaksi/setAllTransaction');
      // 					// 		this.$store.dispatch('modCart/setCart');
      // 					// 		this.$router.push({ name: 'home'});
      // 					this.showAlert('success', 'Please cek your email');
      // 				}, 2000);
      // 				// } else {
      // 				// 	this.loadingSpinner = false;
      // 				// 	this.showAlert('error', 'Invalid data');
      // 				// };
      // 			})
      // 			.catch(err => {
      // 				this.loadingSpinner = false;
      // 				this.showAlert('error', 'Invalid data');
      // 			});
      // 	} catch (error) {
      // 		this.loadingSpinner = false;
      // 		this.showAlert('error', 'Network Error');
      // 	};
      // } else {
      if (cek) {
        try {
          const res = await axios
            .post(`${process.env.VUE_APP_LOCAL}/v1/reset/password`, data)
            .then((res) => {
              if (res.data.result) {
                setTimeout(() => {
                  this.loadingSpinner = false;
                  this.showAlert("success", "Kode OTP telah berhasil dikirimkan. silahkan cek folder inbox atau spam pada email Anda");

                  this.$router.push({ name: "otp-reset", query: { ph: this.email } });
                  this.email = null;
                }, 2000);
              }
            })
            .catch((err) => {
              this.loadingSpinner = false;
              this.showAlert("error", "Invalid data");
            });
        } catch (error) {
          this.loadingSpinner = false;
          this.showAlert("error", "Network Error");
        }
      } else {
        this.loadingSpinner = false;
        this.showAlert("error", "Masukkan data dengan benar");
      }
      // }
    },
  },
  computed: {
    ...mapGetters({
      getDark: "getDark",
      getAuth: "getAuth",
      logo: "logo",
    }),
  },
};
</script>

<style scoped>
.slide-fade-enter-from {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 1s ease 0.3s;
}

.slide-fade-enter-to {
  opacity: 1;
}

/* .slide-fade-leave-from {
		opacity: 1;
	} */
.slide-fade-leave-active {
  transition: opacity 1s;
}

.slide-fade-leave-to {
  opacity: 0;
}
</style>
