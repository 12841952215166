
import axios from 'axios';

export default {
  namespaced: true,
  state: {
    waitingTransaction: [], 
    completedTransaction: [],
    completedPagination: {
      totalItem: 0,
      currentPage: 1,
      perPage: 5,
      totPage: 1,
      skip: 0,
    },
    waitingPagination: {
      totalItem: 0,
      currentPage: 1,
      perPage: 5,
      totPage: 1,
      skip: 0,
    },
    totalCompletedTransaction: 0,
    totalWaitingTransaction: 0,
    invoice: [],
  },
  getters: {
    getCompleted: (state) => {
      return state.completedTransaction;
    },
    getWaiting: (state) => {
      return state.waitingTransaction;
    },
    getTotalWaitingTransaction: (state) => {
      return state.totalWaitingTransaction;
    },
    getCompletedPagination: (state) => {
      return state.completedPagination;
    },
    getWaitingPagination: (state) => {
      return state.waitingPagination;
    },
    getTotalCompletedTransaction: (state) => {
      return state.totalCompletedTransaction;
    },
    getInvoice: (state) => {
      return state.invoice;
    },
  },
  mutations: {
    UPDATE_COMPLETED_TRANSACTION(state, data) {
      state.completedTransaction = data.data;
      // state.totalCompletedTransaction = data.total;
    },
    UPDATE_WAITING_TRANSACTION(state, data) {
      state.waitingTransaction = data.data;
      // state.totalWaitingTransaction = data.total;
    },
    UPDATE_TOTAL_COMPLETED_TRANSACTION(state, data) {
      state.totalCompletedTransaction = data.totalItem;
      state.completedPagination = data;
    },
    UPDATE_TOTAL_WAITING_TRANSACTION(state, data) {
      state.totalWaitingTransaction = data.totalItem;
      state.waitingPagination = data;
    },
    UPDATE_INVOICE(state, data) {
      state.invoice = [];
      var coba = "";
      state.completedTransaction.filter((e) => {
        if (e.id === data) {
          coba = e;
        }
      });
      state.invoice = coba;
    },
  },
  actions: {
    setInvoice({ commit }, data) {
      commit("UPDATE_INVOICE", data);
    },
    async setAllTransaction({ commit, dispatch }, data) { 
       
      if(!data){
        dispatch("setPagination", 0);
      }else{
        dispatch("setPagination", data.skip);
      }
      const personal = JSON.parse(localStorage.getItem("authenticate"));

      //
      try {
        var params = data;
        // if (!data) {
        //   var setLimit = 0;
        //   const limit = localStorage.getItem("limitTransaksi");
        //   if (limit) {
        //     setLimit = limit;
        //   } else {
        //     setLimit = 100;
        //   }
        //   params = {
        //     limit: setLimit,
        //     skip: 0,
        //     sort: "tahun DESC",
        //   };
        // } else {
        // params = data;
        // }
        // console.log(params);
        // console.log("========================");
        const token = localStorage.getItem("authenticate");
        var config = {
          method: "get",
          url: `${process.env.VUE_APP_LOCAL}/v1/transaction`,
          params: params,
          headers: {
            Authorization: `Bearer ${JSON.parse(token).AUTH_TOKEN}`,
          },
        };

        await axios(config)
          .then((res) => {
            // console.log(res.data.result.length);
            const qryCompleted = {
              data: [],
              total: 0,
            };
            const qryWaiting = {
              data: [],
              total: 0,
            };
            const qryCanceled = {
              data: [],
              total: 0,
            }; 
            // if (res.data.result != null) {
            if (res.data.result != null) {
              for (let i = 0; i < res.data.result.length; i++) {
                if (personal.profile.id === res.data.result[i].id_member) {
                  if (res.data.result[i]["status"] === "Completed") {
                    qryCompleted.data.push({
                      id: res.data.result[i]["id"],
                      code: res.data.result[i]["code"],
                      id_member: res.data.result[i]["id_member"],
                      invoice_url: res.data.result[i]["invoice_url"],
                      member_payload: res.data.result[i]["member_payload"],
                      id_category: res.data.result[i]["id_category"],
                      category: res.data.result[i]["category"],
                      id_category_sub: res.data.result[i]["id_category_sub"],
                      category_sub: res.data.result[i]["category_sub"],
                      id_product: res.data.result[i]["id_product"],
                      product_name: res.data.result[i]["product_name"],
                      product_payload: res.data.result[i]["product_payload"],
                      price: res.data.result[i]["price"],
                      tahun: res.data.result[i]["tahun"],
                      status: res.data.result[i]["status"],
                      is_download: res.data.result[i]["is_download"],
                      created_at: res.data.result[i]["created_at"],
                      payment_at: res.data.result[i]["payment_at"],
                      updated_at: res.data.result[i]["updated_at"],
                    });

                    // qryCompleted.total += 1;
                  } else if (
                    res.data.result[i]["status"] === "Waiting Payment"
                  ) {
                    qryWaiting.data.push({
                      id: res.data.result[i]["id"],
                      code: res.data.result[i]["code"],
                      id_member: res.data.result[i]["id_member"],
                      invoice_url: res.data.result[i]["invoice_url"],
                      member_payload: res.data.result[i]["member_payload"],
                      id_category: res.data.result[i]["id_category"],
                      category: res.data.result[i]["category"],
                      id_category_sub: res.data.result[i]["id_category_sub"],
                      category_sub: res.data.result[i]["category_sub"],
                      id_product: res.data.result[i]["id_product"],
                      product_name: res.data.result[i]["product_name"],
                      product_payload: res.data.result[i]["product_payload"],
                      price: res.data.result[i]["price"],
                      tahun: res.data.result[i]["tahun"],
                      status: res.data.result[i]["status"],
                      is_download: res.data.result[i]["is_download"],
                      created_at: res.data.result[i]["created_at"],
                      payment_at: res.data.result[i]["payment_at"],
                      updated_at: res.data.result[i]["updated_at"],
                    });

                    // qryWaiting.total += 1;
                  } else {
                    qryCanceled.data.push({
                      id: res.data.result[i]["id"],
                      code: res.data.result[i]["code"],
                      id_member: res.data.result[i]["id_member"],
                      invoice_url: res.data.result[i]["invoice_url"],
                      member_payload: res.data.result[i]["member_payload"],
                      id_category: res.data.result[i]["id_category"],
                      category: res.data.result[i]["category"],
                      id_category_sub: res.data.result[i]["id_category_sub"],
                      category_sub: res.data.result[i]["category_sub"],
                      id_product: res.data.result[i]["id_product"],
                      product_name: res.data.result[i]["product_name"],
                      product_payload: res.data.result[i]["product_payload"],
                      price: res.data.result[i]["price"],
                      tahun: res.data.result[i]["tahun"],
                      status: res.data.result[i]["status"],
                      is_download: res.data.result[i]["is_download"],
                      created_at: res.data.result[i]["created_at"],
                      payment_at: res.data.result[i]["payment_at"],
                      updated_at: res.data.result[i]["updated_at"],
                    });

                    // qryCanceled.total += 1;
                  }
                }
              }
            }

            // localStorage.setItem("totOrder", qryCompleted.total);
            // localStorage.setItem("totWaitPay", qryWaiting.total);
            // localStorage.setItem("totCanceledPay", qryCanceled.total);

            commit("UPDATE_COMPLETED_TRANSACTION", qryCompleted);
            commit("UPDATE_WAITING_TRANSACTION", qryWaiting);
          })
          .catch((e) => console.log(e));
      } catch (error) {
        console.log("terjadi kesalahan server");
      }
    },

    async setPagination({ commit }, data) {
      const personal = JSON.parse(localStorage.getItem("authenticate"));

      //
      try {
        const token = localStorage.getItem("authenticate");

        var setLimit = 100;
        const params = {
          limit: setLimit,
          skip: 0,
          sort: "tahun DESC",
        };

        var config = {
          method: "get",
          url: `${process.env.VUE_APP_LOCAL}/v1/transaction`,
          params: params,
          headers: {
            Authorization: `Bearer ${JSON.parse(token).AUTH_TOKEN}`,
          },
        };

        await axios(config)
          .then((res) => {
            const qryCompleted = {
              totalItem: 0,
              currentPage: 1,
              perPage: 5,
              totPage: 1,
              skip: 0,
            };
            const qryWaiting = {
              totalItem: 0,
              currentPage: 1,
              perPage: 5,
              totPage: 1,
              skip: 0,
            };
            const qryCanceled = {
              totalItem: 0,
              currentPage: 1,
              perPage: 5,
              totPage: 1,
              skip: 0,
            }; 
            if (res.data.result != null) {
              for (let i = 0; i < res.data.result.length; i++) {
                if (personal.profile.id === res.data.result[i].id_member) {
                  if (res.data.result[i]["status"] === "Completed") {
                    qryCompleted.totalItem += 1;
                  } else if ( res.data.result[i]["status"] === "Waiting Payment" ) {
                    qryWaiting.totalItem += 1;
                  } else {
                    qryCanceled.total += 1;
                  }
                }
              }
            }
 
            qryCompleted.totPage = Math.ceil(qryCompleted.totalItem / qryCompleted.perPage); 
            qryCompleted.skip = data; 
            qryCompleted.currentPage = (data / qryCompleted.perPage) + 1; 

            qryWaiting.totPage = Math.ceil(qryWaiting.totalItem / qryWaiting.perPage); 
            qryWaiting.skip = data; 
            qryWaiting.currentPage = (data / qryWaiting.perPage) + 1; 
            

            localStorage.setItem("totOrder", qryCompleted.totalItem);
            localStorage.setItem("totWaitPay", qryWaiting.totalItem);

            commit("UPDATE_TOTAL_COMPLETED_TRANSACTION", qryCompleted);
            commit("UPDATE_TOTAL_WAITING_TRANSACTION", qryWaiting);
          })
          .catch((e) => console.log(e));
      } catch (error) {
        console.log("terjadi kesalahan server");
      }
    },
  },
};