<template>
    <div
    :class="warna" class="flex items-center justify-center ease-in-out duration-300">
        <svg class="w-5 h-5 text-white animate-spin left-1/2 mr-2" fill="none" viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <circle 
    :class="warna" class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
            </circle>
            <path class="opacity-75"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                fill="currentColor" />
        </svg>
        <span class="font-bold">Loading ... </span>
    </div>
</template>
<script>
export default {
    props: {
        warna: {
            type: String,
            default: 'text-white'
        }
    }
}
</script>