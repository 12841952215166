<template>
  <div class="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
    <div class="md:my-5 flex justify-between items-center mb-3">
      <div>
        <h1 class="text-2xl lg:text-2xl tracking-tight font-semibold text-gray-600 sm:text-3xl">
          <span class="mr-2 text-slate-500">New</span><span
            :class="getDark ? 'text-white':'text-slate-800'">Products</span>
        </h1>
      </div>
    </div>
    <div class="relative">
      <swiper :slides-per-view="2" :space-between="20" :loop="loop" :centeredSlides="false" :autoplay="{
          delay: 10000,
          disableOnInteraction: false,
        }" :breakpoints="{
          '640': {
            slidesPerView: 2,
          },
          '768': {
            slidesPerView: 3,
          },
          '1024': {
            slidesPerView: 5,
          },
        }" :modules="modules" @swiper="onSwiper" @slideChange="onSlideChange"
        :navigation="{ nextEl: '.nextArrow', prevEl: '.prevArrow' }">
        <swiper-slide class="group mt-2 mb-10" v-for="product in getNewProducts" :key="product.id"
          @click="viewProduct(product.id)">
          <!-- AppCard Component -->
          <!-- <VueCustomTooltip label="This is a tooltip"> -->
          <app-card :product="{
              title: product.product_name,
              img: product.image ? product.image : gambar,
              imgSize: 'h-[200px] md:h-[150px]',
              titleSize: 'text-[14px]',
              sub_category: product.sub_category,
              sub_categorySize: 'text-sm',
              slug: product.slug,
              inital_price: product.inital_price,
              price: product.price,
              discount_one: product.discount_one,
              discount_two: product.discount_two,
            }"></app-card>
          <!-- </VueCustomTooltip> -->
          <!-- AppCard Component END -->
        </swiper-slide>
      </swiper>
      <div class="parallax-slider-navigation absolute right-5 -top-12 flex justify-end">
        <div
          class="nav-indicator prevArrow fa-2x hover:cursor-pointer bg-white border border-gray-400 ml-2 hover:bg-primary h-9 w-9 flex justify-center items-center rounded-full hover:text-white">
          <i class="fa-solid text-lg font-bold fa-chevron-left"></i>
        </div>
        <div
          class="nav-indicator nextArrow fa-2x hover:cursor-pointer bg-white border border-gray-400 ml-2 hover:bg-primary h-9 w-9 flex justify-center items-center rounded-full hover:text-white">
          <i class="fa-solid text-lg font-bold fa-chevron-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppCard from "../../components/product/CardNew.vue";
//https://swiperjs.com/
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Mixin from "@/mixin/index";
export default {
  name: "Slider",
  mixins: [Mixin],
  data() {
    const onSwiper = (swiper) => {
      // console.log('slider');
    };
    const onSlideChange = (swiper) => {
      // console.log(swiper.touchEvents);
    };
    return {
      onSwiper,
      onSlideChange,
      loop: true,
      modules: [Autoplay, Pagination, Navigation],
      gambar: `${process.env.VUE_APP_IMAGE}/img/cover.jpg`,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    AppCard,
  },
  // methods: {
  //     viewAllProduct() {
  //         this.loader();
  //         this.$router.push({ name: 'products' });
  //     },
  // },
  computed: {
    ...mapGetters({
      getNewProducts: "modProducts/getNewProducts",
      getDark: "getDark",
    }),
  },
};
</script>

<style scoped>
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
