<template>
    <div v-if="getSimiliarProducts" class="sm:pt-5 pb-20 lg:container mx-auto lg:w-10/12 px-5 lg:px-0" ref="boxLoading">
        <div class="md:my-5 flex justify-between  items-center mb-3">
            <div>
                <h1 class="text-2xl lg:text-2xl tracking-tight font-semibold text-gray-600 sm:text-3xl ">
                    <span class="mr-2 text-slate-500">Similar</span>
                    <span :class="getDark ? 'text-zinc-200' : 'text-slate-800'">Products</span>
                </h1>
            </div>
            <!-- <div @click.prevent="viewAllProduct" class="bg-primary hover:bg-secondary hover:cursor-pointer
        text-white rounded-full py-2 px-4 text-sm flex justify-center">
            <div class="items-center">view all</div>
        </div> -->
        </div>
        <!-- <swiper
        :slides-per-view="2"
        :space-between="30"
        :loop="loop"
        :centeredSlides="false"
        :autoplay="{
            delay: 3500,
            disableOnInteraction: false,
        }"
        :breakpoints="{
        '640': {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        '768': {
            slidesPerView: 4,
            spaceBetween: 20,
        },
        '1024': {
            slidesPerView: 5,
            spaceBetween: 40,
        },
        }"
        :navigation="false"
        :modules="modules"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        >  -->
        <div class="grid grid-cols-12 items-center gap-2">
            <div class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 group hover:cursor-pointer w-full"
                v-for="product in getSimiliarProducts.slice(0, 4)" :key="product.id" @click.prevent="viewProduct(product.id)">
                <!-- AppCard Component -->
                <app-card :product="{
                    title: product.product_name,
                    titleSize: 'text-[16px] leading-[16px]',
                    // priceWidth:  'col-span-6 mr-2',  
                    imgSize: 'h-[438px] md:h-[274px]',
                    inital_price: product.inital_price,
                    price: product.price,
                    discount_one: product.discount_one,
                    discount_two: product.discount_two,
                    sub_category: product.sub_category,
                    slug: product.slug,
                    img: product.image ? product.image : gambar,
                    button: true,
                }"></app-card>
                <!-- AppCard Component END -->
            </div>
        </div>
        <!-- </swiper> -->
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import AppCard from './CardRecomended.vue';
    //https://swiperjs.com/
    // Import Swiper Vue.js components
    import { Swiper, SwiperSlide  } from 'swiper/vue';
    import {  Autoplay, Pagination, Navigation } from "swiper";
	import Mixin from '@/mixin/index.js';

    // Import Swiper styles
    import 'swiper/css';
    import 'swiper/css/autoplay';
    import "swiper/css/pagination";
    import "swiper/css/navigation";
    export default {
        name: 'SimiliarProducts',
        mixins: [Mixin],
        data() {
            const onSwiper = (swiper) => {
                // console.log(swiper);
            };
            const onSlideChange = () => {
                // console.log('slide change');
            };
            return {
                onSwiper,
                onSlideChange,
                loop: true,
                modules: [Autoplay, Pagination, Navigation],
                gambar: `${process.env.VUE_APP_IMAGE}/img/cover.jpg`,
                // fullPage: true,
            };
        },
        components: {
            Swiper,
            SwiperSlide,
            AppCard,
        },
        methods: {            
            // viewProduct(product_name, slug) {
            //     this.loader();

            //     // let loader = this.$loading.show({
            //     //     // Optional parameters
            //     //     container: this.fullPage ? null : this.$refs.boxLoading,
            //     //     // container: this.fullPage ? null : this.$refs.boxLoading,
            //     //     canCancel: false,
            //     //     // onCancel: this.yourCallbackMethod,
            //     //     color: '#fff',
            //     //     loader: 'dots', //spinner
            //     //     width: 100,
            //     //     height: 100,
            //     //     backgroundColor: '#000',
            //     //     opacity: 0.7,
            //     //     zIndex: 999,
            //     //     // onCancel: this.onCancel,
            //     // });
            //     // // simulate AJAX
            //     // setTimeout(() => {
            //     //     const qryProduct = {
            //     //         filter_product_name: product_name,
            //     //     }
            //     //     this.$store.dispatch('setDetailProduct', qryProduct);
            //     //     this.$router.push({ name: 'productDetail', params:{ slug: product_name, slug} });
            //     //     // this.$store.dispatch('setProductSearch', category);
                    
            //     //     loader.hide();
            //     // }, 3000); 
            // },
        },
        computed: {
            ...mapGetters({
                getSimiliarProducts: 'modProducts/getSimiliarProducts',
                getDark: 'getDark',
            }),
        },
    };
</script>

<style scoped>
    .swiper {
    width: 100%;
    height: 100%;
    }

    .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    }

    .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    }
</style>