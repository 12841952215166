<template>
  <div
    :class="getDark ? 'text-gray-500' : 'text-slate-800'"
    class="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8"
  >
    <div class="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
      <div class="sm:col-span-2">
        <router-link :to="{ name: 'home' }">
          <img class="h-8 sm:h-10 -ml-2" :src="logo" alt="" />
        </router-link>
        <div class="mt-6 lg:max-w-sm">
          <!-- <p class="text-sm" v-html="getContent.footer.content"></p> -->
          <li
            class="cursor-pointer list-none hover:font-bold py-1 group"
            v-for="(item, index) in menuUtama"
            :key="index"
            v-on:click.prevent="goTo(item.href, close)"
          >
            <span class="group-hover:bg-slate-100 shadow-sm rounded-md p-2">{{
              item.name
            }}</span>
          </li>
        </div>
      </div>
      <div class="space-y-2 text-sm">
        <p class="text-base font-bold tracking-wide text-gray-400">Contacts</p>
        <div class="flex">
          <p class="mr-1">Phone:</p>
          <a
            href="tel:{{ getCompany.phone }}"
            aria-label="Our phone"
            title="Our phone"
            :class="
              getDark
                ? 'text-gray-300 hover:text-gray-100'
                : 'text-deep-purple-accent-400 hover:text-deep-purple-800'
            "
            class="transition-colors"
            >{{ getCompany.phone }}</a
          >
        </div>
        <div class="flex">
          <p class="mr-1">Email:</p>
          <a
            :href="`mailto:${getCompany.email}`"
            aria-label="Our email"
            title="Our email"
            :class="
              getDark
                ? 'text-gray-300 hover:text-gray-100'
                : 'text-deep-purple-accent-400 hover:text-deep-purple-800'
            "
            class="transition-colors"
            >{{ getCompany.email }}</a
          >
        </div>
        <div class="flex">
          <a
            :href="company.googleMap"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="adress"
            title="adress"
            :class="
              getDark
                ? 'text-gray-300 hover:text-gray-100'
                : 'text-deep-purple-accent-400 hover:text-deep-purple-800'
            "
            class="transition-colors"
          >
            {{ getCompany.address }}
          </a>
        </div>
      </div>
      <div class="text-left w-full">
        <span class="text-base font-bold tracking-wide text-gray-900">Social</span>
        <div class="flex items-center justify-start mt-1 space-x-3">
          <a
            v-for="(sosmed, index) in socialMedia"
            :key="index"
            :href="`${sosmed.href}`"
            target="_blank"
            :class="
              getDark
                ? 'text-gray-500 hover:text-gray-100'
                : 'text-gray-500 hover:text-deep-purple-accent-400'
            "
            class="transition-colors duration-300"
          >
            <i :class="sosmed.fa"></i>
          </a>
        </div>
        <p class="mt-4 text-sm text-gray-500">
          <!-- Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, enim. -->
        </p>
      </div>
    </div>
    <div
      :class="getDark ? 'border-gray-800' : 'border-gray-300'"
      class="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row"
    >
      <p class="text-sm">©{{ new Date().getFullYear() }} Infobank - Biro Reset</p>
      <!-- <ul :class="getDark ? 'text-gray-200' : 'text-gray-700'"
        class="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
        <li>
          <router-link to="/"
            class="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400">F.A.Q
          </router-link>
        </li>
        <li>
          <router-link to="/"
            class="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400">Privacy
            Policy</router-link>
        </li>
        <li>
          <router-link to="/"
            class="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400">Terms &amp;
            Conditions</router-link>
        </li>
      </ul> -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

const socialMedia = [
  {
    name: "Twitter",
    href: "https://twitter.com/infobanknews",
    fa: "fa-brands fa-twitter",
  },
  {
    name: "Facebook",
    href: "https://www.facebook.com/majalahinfobank",
    fa: "fa-brands fa-facebook-f",
  },
  {
    name: "Youtube",
    href: "https://www.youtube.com/watch?v=EN81kC6c65I",
    fa: "fa-brands fa-youtube",
  },
  {
    name: "Instagram",
    href: "https://www.instagram.com/infobank_official/",
    fa: "fa-brands fa-instagram",
  },
];
export default {
  name: "Footer",
  data() {
    return {
      socialMedia,
    };
  },
  methods: {
    goTo(i) {
      this.$store.dispatch("modMenu/setAktifMenu", i);
      this[i](i);
      close();
    },
    home(i) {
      this.$router.push({ name: i });
    },
    about(i) {
      this.$router.push({ name: i });
    },
    products(i) {
      this.$router.push({ name: i });
    },
    contact(i) {
      this.$router.push({ name: i });
    },
    profile() {
      this.$router.push({ name: "profile" });
    },
  },
  computed: {
    ...mapGetters({
      getDark: "getDark",
      company: "modProfile/getCompany",
      getCompany: "modContent/getCompany",
      getContent: "modContent/getContent",
      menuUtama: "modMenu/getMenuUtama",
      logo: "logo",
    }),
  },
};
</script>
