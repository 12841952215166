<template>
    <div :class="getDark ? 'bg-zinc-800':'bg-zinc-200'" class="h-screen w-full">
        <div class="pt-[20vh] h-full w-full z-20">
            <div class="flex max-w-sm mx-auto overflow-hidden rounded-lg
			shadow-none sm:shadow-lg lg:max-w-lg bg-transparent sm:bg-white">
                <div class="w-full px-6 py-8 md:px-8">

                    <div class="w-full flex justify-center">
                        <router-link :to="{ name: 'home' }" class="text-5xl cursor-pointer font-semibold">
                            <img class="h-8 w-auto sm:h-[50px] cursor-pointer" :src="logo" alt="" />
                        </router-link>
                    </div>
                    <div class="flex items-center justify-between mt-4">
                        <span class="w-1/5 border-b dark:border-primary lg:w-1/4"></span>

                        <a href="javascript:void(0)"
                            class="text-xs text-center text-secondary uppercase dark:text-gray-400 hover:underline">OTP</a>

                        <span class="w-1/5 border-b dark:border-gray-400 lg:w-1/4"></span>
                    </div>

                    <form @submit.prevent="sendOtp" ref="formOtp">
                        <div class="mt-4 flex justify-between">
                            <input
                                class="otp mr-4 w-14 text-2xl text-center px-4 py-2 text-gray-700 bg-slate-100 border-slate-300 border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-primary focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                                type="text" id="demo" @input='digitValidate' v-on:keyup='tabChange(1)' maxlength=1
                                v-model='otp1'>

                            <input
                                class="otp mr-4 w-14 text-2xl text-center px-4 py-2 text-gray-700 bg-slate-100 border-slate-300 border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-primary focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                                type="text" id="demo" @input='digitValidate' v-on:keyup='tabChange(2)' maxlength=1
                                v-model='otp2'>

                            <input
                                class="otp mr-4 w-14 text-2xl text-center px-4 py-2 text-gray-700 bg-slate-100 border-slate-300 border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-primary focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                                type="text" id="demo" @input='digitValidate' v-on:keyup='tabChange(3)' maxlength=1
                                v-model='otp3'>

                            <input
                                class="otp mr-4 w-14 text-2xl text-center px-4 py-2 text-gray-700 bg-slate-100 border-slate-300 border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-primary focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                                type="text" id="demo" @input='digitValidate' v-on:keyup='tabChange(4)' maxlength=1
                                v-model='otp4'>

                            <input
                                class="otp mr-4 w-14 text-2xl text-center px-4 py-2 text-gray-700 bg-slate-100 border-slate-300 border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-primary focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                                type="text" id="demo" @input='digitValidate' v-on:keyup='tabChange(5)' maxlength=1
                                v-model='otp5'>

                            <input
                                class="otp mr-4 w-14 text-2xl text-center px-4 py-2 text-gray-700 bg-slate-100 border-slate-300 border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-primary focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                                type="text" id="demo" @input='digitValidate' v-on:keyup='tabChange(6)' maxlength=1
                                v-model='otp6'>
                        </div>
                        <button
                            class="w-full mt-4 px-4 py-2 tracking-wide text-black transition-colors duration-200 transform bg-transparent rounded outline-none"
                            v-if="loadingSpinner">
                            <global-loading warna="text-slate-800"></global-loading>
                        </button>
                    </form>
                </div>
            </div>


        </div>
    </div>
</template>
<script>
import axios from 'axios';
import GlobalLoading from '@/components/GlobalLoading.vue';
import Alert from '@/mixin';
import { mapGetters } from 'vuex';
import { useRoute } from 'vue-router';


export default {
    name: "Otp",
    components: {
        GlobalLoading,
    },
    mixins: [Alert],
    data() {
        return {
            titleApp: process.env.VUE_APP_NAMES,
            loadingSpinner: false,
            show: false,
            otp1: '',
            otp2: '',
            otp3: '',
            otp4: '',
            otp5: '',
            otp6: '',
            noTelp: '',
        }
    },
    created() {
        const route = useRoute()
        this.noTelp = route.query.ph;
    },
    computed: {
        ...mapGetters({
            getDark: 'getDark',
            logo: "logo"
        }),
    },
    mounted() {
        let ele = document.querySelectorAll('#demo');
        ele[0].focus()
    },
    methods: {
        goTo() {
            this.$router.push({ name: 'home' });
        },
        digitValidate(e) {
            e.target.value = e.target.value.replace(/[^0-9]/g, '');
        },
        tabChange(val) {
            let ele = document.querySelectorAll('#demo');
            if (ele[val - 1].value != '') {
                if ((val - 1) < 5) {
                    ele[val].focus()
                } else {
                    ele[0].focus()

                    this.loadingSpinner = true;
                    setTimeout(() => {
                        this.sendOtp()
                    }, 2000);
                }
            }
        }
        ,
        login() {
            this.$router.push({ name: 'login' })
        },
        async sendOtp() {
            const noOtp = `${this.otp1}${this.otp2}${this.otp3}${this.otp4}${this.otp5}${this.otp6}`;
            const datOtp = {
                "phone": this.noTelp,
                "purpose": "Reset Password",
                "otp": noOtp
            };
            const daPre = JSON.stringify(datOtp);
            try {
                await axios.post(`${process.env.VUE_APP_LOCAL}/v1/verify/otp`, daPre)
                    .then(res => { 
                        const newToken = res.data.result['x-token'];

                        // if (res.data.result) {
                            setTimeout(() => {
                                this.loadingSpinner = false;
                                this.otp1 = '';
                                this.otp2 = '';
                                this.otp3 = '';
                                this.otp4 = '';
                                this.otp5 = '';
                                this.otp6 = '';
                                // this.register(daReg)
                            }, 2000);

                            this.$router.push({ name: 'reset-password', query: {ib: newToken} })
                        // } else {
                        //     this.errMessage = 'OTP Failed';
                        //     setTimeout(() => {
                        //         this.showAlert('error', this.errMessage);
                        //         this.loadingSpinner = false;
                        //         this.otp1 = '';
                        //         this.otp2 = '';
                        //         this.otp3 = '';
                        //         this.otp4 = '';
                        //         this.otp5 = '';
                        //         this.otp6 = '';
                        //     }, 2000);
                        // };
                    })
                    .catch((err) => {
                        this.errMessage = 'OTP Failed';
                        setTimeout(() => {
                            this.showAlert('error', this.errMessage);
                            this.loadingSpinner = false;
                            this.otp1 = '';
                            this.otp2 = '';
                            this.otp3 = '';
                            this.otp4 = '';
                            this.otp5 = '';
                            this.otp6 = '';
                        }, 2000);
                    });
            } catch (error) {
                this.errMessage = 'OTP Failed';
                setTimeout(() => {
                    this.showAlert('error', this.errMessage);
                    this.loadingSpinner = false;
                }, 2000);
            };
        },

        async register(daReg) {
            try {
                await axios.post(`${process.env.VUE_APP_LOCAL}/v1/register`, daReg)
                    .then(res => {
                        if (res.status == 200) {
                            this.errMessage = res.data.message;
                            this.errMessage = 'Login Successfully';
                            setTimeout(() => {
                                this.$store.dispatch('setShowAlert', {
                                    message: this.errMessage,
                                    color: 'bg-green-300',
                                });
                            }, 1000);
                            setTimeout(() => {
                                this.loadingSpinner = false;
                                this.$store.dispatch('setShowAlert', {
                                    message: this.errMessage,
                                    color: 'bg-green-300',
                                });
                            }, 2000);
                            this.$router.push({ name: 'login' })
                        } else {
                            this.errMessage = 'Network Error';
                            setTimeout(() => {
                                this.showAlert('error', this.errMessage);
                                this.loadingSpinner = false;
                            }, 2000);
                        };
                    })
                    .catch((err) => {
                        this.errMessage = "Email or Phone existing used";
                        setTimeout(() => {
                            this.showAlert('error', this.errMessage);
                            this.loadingSpinner = false;
                        }, 2000);
                    });
            } catch (error) {
                this.errMessage = 'Register Failed';
                setTimeout(() => {
                    this.showAlert('error', this.errMessage);
                    this.loadingSpinner = false;
                }, 2000);
            };
        }
    }
}
</script>

<style scoped>
.slide-fade-enter-from {
    opacity: 0;
}

.slide-fade-enter-active {
    transition: all 1s ease 0.3s;
}

.slide-fade-enter-to {
    opacity: 1;
}

.slide-fade-leave-active {
    transition: opacity 1s;
}

.slide-fade-leave-to {
    opacity: 0;
}
</style>