import { createApp } from 'vue'
import App from './App.vue'

// vue-loader 
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

// import './registerServiceWorker'
import router from './router'
import store from './store'
import moment from 'moment';
import VeeValidatePlugin from './includes/validation';

import './main.css'

import vTitle from 'vuejs-title'

import VueAwesomePaginate from "vue-awesome-paginate";

// import the necessary css file
import "vue-awesome-paginate/dist/style.css";

// createApp(App).use(moment).use(store).use(router).mount('#app')
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import axios from 'axios';
import VueCookies from 'vue3-cookies';




// import VueCustomTooltip from "@adamdehaven/vue-custom-tooltip";
import FloatingVue from "floating-vue";

// if(VueCookies.get('AUTH_TOKEN')){
// axios.defaults.headers.common['Authorization'] = `Bearer ${VueCookies.get('AUTH_TOKEN')}`;
// }
axios.defaults.baseURL = process.env.VUE_APP_HTTPSERVICE
// axios.defaults.headers.common['Authorization'] = `Bearer ${VueCookies.get('AUTH_TOKEN')}`;
// axios.defaults.headers.common['Accept']='application/json'
// axios.defaults.headers.common['Content-Type']='application/json'  
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = true
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.withCredentials = true;
// axios.defaults.timeout = 5000;


//   Vue.prototype.$http = axios;
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(far);
import { dom } from "@fortawesome/fontawesome-svg-core";
import './registerServiceWorker'
dom.watch();

const app =  createApp(App);
app.use(store);
app.use(router);
app.use(VueLoading);
app.use(VeeValidatePlugin);
app.config.globalProperties.$axios = axios;
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(VueSweetalert2);
app.use(vTitle);

app.use(VueAwesomePaginate)

// app.use(VueCustomTooltip, {
//   name: "VueCustomTooltip",
//   color: "#fff",
//   background: "#000",
//   borderRadius: 100,
//   fontWeight: 400,
// });
app.use(FloatingVue);
import "floating-vue/dist/style.css";

app.use(moment).use(VueCookies, {
  expireTimes: "30d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "None"
});
app.mount('#app');