<template>
    <label
        :class="getDark ? 'text-zinc-400':'text-slate-500'"
        class="block mb-2 text-sm font-medium"
        :for="nama">{{ label }}</label>
    <vee-field
    v-bind="$attrs"
    :value="modelValue" 
    :id="nama"
    :name="nama"
    autocomplete="off"
    :class="getDark ? 'bg-zinc-800 border-zinc-800 text-zinc-500'
        :'bg-zinc-200 border-zinc-200 text-zinc-800'"
    class="block w-full px-4 py-2 text-gray-700 dark:bg-slate-900
    border"
    />
    <ErrorMessage class="text-red-500 block" :name="nama" />
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        nama: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        modelValue: {
            type: [String, Number],
            default: ''
        }
    },
    computed: {
        ...mapGetters({
            getDark: 'getDark',
        })
    }
}
</script>