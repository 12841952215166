<template>
  <div
    class="bg-transparent px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8"
  >
    <div class="flex flex-wrap lg:justify-center justify-between items-start">
      <!-- left -->
      <div class="w-full xl:w-1/4 lg:w-2/5 lg:px-4 pt-5 lg:py-10">
        <div
          :class="getDark ? 'bg-zinc-800 border-none' : ''"
          class="px-3 pt-3 pb-5 bg-slate-100 shadow-lg"
        >
          <!-- Paket Rating Button  -->
          <button
            @click="setPaketRating(true)"
            :class="paketRating ? 'bg-primary text-white' : 'bg-[#4361ee] text-white'"
            class="button px-4 font-semibold hover:bg-primary hover:text-white mb-2 w-full text-lg py-2"
          >
            {{ getCategoryPaketRating.category }}
            <span v-if="paketRating">
              <i
                class="fa-solid fa-minus float-right p-1 rounded-full bg-white text-slate-800"
              ></i
            ></span>
            <span v-else>
              <i
                class="fa-solid fa-plus float-right p-1 rounded-full bg-white text-slate-800"
              ></i
            ></span>
          </button>
          <Transition>
            <div v-if="paketRating" mode="out-in" class="px-2 mb-5">
              <!-- CHECKBOX SUB CATEGORY  -->
              <h2
                :class="getDark ? 'text-white' : 'text-slate-600'"
                class="w-full font-semibold text-xl py-2"
              >
                Category
              </h2>
              <div class="flex justify-start">
                <div class="ml-3">
                  <div
                    v-for="item in getCategoryPaketRating.category_sub"
                    :key="item.id"
                    class="form-check"
                  >
                    <input
                      class="form-check-input rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      name="flexRadioDefault"
                      :id="`formkat${item.id}`"
                      :value="item.id"
                      @change="changeCategory(item.id)"
                    />
                    <label
                      :class="
                        getDark
                          ? 'text-zinc-400 hover:text-zinc-200'
                          : 'text-slate-500 hover:text-slate-800'
                      "
                      class="form-check-label cursor-pointer inline-block text-sm hover:font-bold"
                      :for="`formkat${item.id}`"
                    >
                      {{ item.sub_category }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <!-- Paket Tahunan  Button  -->
          <button
            @click="setPaketRating(false)"
            :class="!paketRating ? 'bg-primary text-white' : 'bg-[#4361ee] text-white'"
            class="button px-4 font-semibold hover:bg-primary hover:text-white mb-2 w-full text-lg py-2"
          >
            {{ getKinerjaKeuangan.category }}
            <span v-if="paketRating">
              <i
                class="fa-solid fa-plus float-right p-1 rounded-full bg-white text-slate-800"
              ></i
            ></span>
            <span v-else>
              <i
                class="fa-solid fa-minus float-right p-1 rounded-full bg-white text-slate-800"
              ></i
            ></span>
          </button>
          <Transition mode="out-in">
            <div v-if="!paketRating">
              <!-- CHECKBOX TAHUN  -->
              <div>
                <h2
                  :class="getDark ? 'text-zinc-300' : ''"
                  class="w-full font-semibold text-xl py-2"
                >
                  Tahun
                </h2>
                <!-- -->
                <Multiselect
                  placeholder="Pilih Tahun"
                  v-model="pilihTahun"
                  :options="getTahunProduct"
                  mode="tags"
                  :searchable="true"
                  :createTag="true"
                  class="cursor-pointer"
                />
              </div>
              <!-- CHECKBOX SUB CATEGORY  -->
              <div>
                <h2
                  :class="getDark ? 'text-white' : 'text-slate-600'"
                  class="w-full font-semibold text-xl py-2"
                >
                  Category
                </h2>
                <div class="flex justify-start">
                  <div class="ml-3">
                    <div
                      v-for="item in getKinerjaKeuangan.category_sub"
                      :key="item.id"
                      class="form-check"
                    >
                      <input
                        class="form-check-input rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="radio"
                        name="flexRadioDefault"
                        :id="`formkat${item.id}`"
                        :value="item.id"
                        v-model="pilihKategori"
                      />
                      <label
                        :class="
                          getDark
                            ? 'text-zinc-400 hover:text-zinc-200'
                            : 'text-slate-500 hover:text-slate-800'
                        "
                        class="form-check-label inline-block text-sm cursor-pointer hover:font-bold"
                        :for="`formkat${item.id}`"
                      >
                        {{ item.sub_category }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full mt-5" v-if="loadingDataTahunan">
                <div
                  class="flex items-center justify-center h-12 px-4 rounded-md bg-orange-400 text-gray-800 ease-in-out duration-300"
                >
                  <svg
                    class="w-5 h-5 text-gray-800 animate-spin left-1/2 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      fill="currentColor"
                    />
                  </svg>
                  <span class="font-bold">Loading ...</span>
                </div>
              </div>
              <div class="w-full mt-5" v-else>
                <button
                  @click="searchDataTahunan"
                  class="button px-4 font-bold bg-orange-400 text-gray-800 rounded-md hover:bg-orange-600 hover:text-whitemt-5 w-full text-lg py-2"
                >
                  Search
                </button>
              </div>
            </div>
          </Transition>
        </div>
      </div>

      <!-- right  -->
      <div class="lg:flex-1 w-full xl:w-3/4 lg:w-3/4 py-5 text-white">
        <div class="w-full mt-5" v-if="loadingDataTahunan">
          <div :class="getDark ? '  text-zinc-300' : 'text-gray-500'" class="p-4">
            <div
              :class="getDark ? '' : 'border-gray-200'"
              class="ml-5 flex justify-center items-center px-4 md:px-10 py-20"
            >
              <div
                :class="getDark ? 'text-zinc-400' : 'text-gray-400'"
                class="dark:bg-gray-800 p-3 flex flex-col justify-center items-center w-full"
              >
                <svg
                  class="w-10 h-10 text-gray-500 animate-spin left-1/2 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    fill="currentColor"
                  />
                </svg>
                <h1
                  class="text-xl font-semibold leading-10 text-center md:w-9/12 lg:w-7/12"
                >
                  Loading
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full mt-5 text-slate-600" v-else>
          <Transition>
            <div v-if="getPencarian.isSearch">
              <div
                v-if="getPencarian.type === 'searching'"
                class="flex justify-between items-center"
              >
                <div
                  :class="getDark ? 'text-zinc-400' : 'text-zinc-600'"
                  class="mb-5 ml-3"
                >
                  Pencarian dengan keyword
                  <span class="font-bold">"{{ getPencarian.keywords }}"</span>
                </div>
                <div
                  @click="refresh"
                  :class="
                    getDark
                      ? 'text-zinc-200 hover:bg-zinc-200 hover:text-zinc-800'
                      : 'text-zinc-600 hover:bg-zinc-200'
                  "
                  class="mr-4 border border-zinc-400 rounded-lg items-center px-4 py-1 hover:cursor-pointer"
                >
                  Refresh
                </div>
              </div>
              <div v-else>
                <!-- <select-category @selectCategory="selectCategory" :perPage="pagination.perPage" :skip="pagination.skip">
                      </select-category> -->
              </div>
              <div class="xl:container my-0 mx-auto px-0">
                <div
                  v-if="getPencarian.data.length > 0"
                  class="flex justify-left flex-wrap group"
                >
                  <div
                    class="md:w-1/2 xl:w-1/4 md:px-2 mb-5 lg:mb-3"
                    v-for="(product, index) in getPencarian.data"
                    :key="index"
                    @click.prevent="viewProduct(product.id)"
                  >
                    <product-card
                      :product="{
                        title: product.product_name,
                        titleSize:
                          'text-2xl leading-5 sm:text-lg md:text-md xl:text-[16px] xl:leading-4',
                        imgSize: 'h-[300px] md:h-[250px]',
                        inital_price: product.inital_price,
                        price: product.price,
                        discount_one: product.discount_one,
                        discount_two: product.discount_two,
                        titlePriceSize:
                          'text-[24px] leading-5 sm:text-lg md:text-md lg:text-md xl:text-[14px] xl:leading-4',
                        category: product.sub_category,
                        img: product.image ? product.image : gambar,
                        button: true,
                      }"
                    ></product-card>
                  </div>
                  <div class="w-full flex my-5">
                    <!-- <vue3-paginate :class="getDark ? 'bg-zinc-800 ml-2 text-zinc-50' : 'ml-2 text-zinc-800'"
                      :pageClasses="getDark ? 'bg-zinc-800 hover:bg-zinc-600 px-4 py-2 border border-zinc-700':'bg-zinc-50 hover:bg-zinc-300 text-zinc-800 px-4 py-2 border border-zinc-300'"
                      :activePageClasses="getDark ? 'bg-blue-800 text-white hover:bg-blue-800 px-4 py-2 border border-blue-800':'bg-blue-800 text-white hover:bg-blue-800 text-zinc-50 px-4 py-2 border border-blue-800'"
                      :totalPage=pagination.totPage :pageCount=pagination.totalItem :currentPage=pagination.currentPage
                      @pageChange="functionName">
                      v-if="getPencarian.total > pagination.perPage">
                    </vue3-paginate> -->
                  </div>
                </div>

                <!-- empty searching  -->
                <div
                  v-else
                  :class="getDark ? '' : 'border-gray-200'"
                  class="mx-auto flex justify-center items-center px-4 md:px-10 py-20"
                >
                  <div
                    :class="getDark ? 'text-zinc-400' : 'text-gray-800'"
                    class="dark:bg-gray-800 p-3 flex flex-col justify-center items-center w-full"
                  >
                    <h1
                      class="text-3xl lg:text-4xl font-semibold leading-10 text-center md:w-9/12 lg:w-7/12"
                    >
                      Data Not Found
                    </h1>
                    <p
                      :class="getDark ? 'text-zinc-600' : 'text-gray-600'"
                      class="text-base leading-normal text-center md:w-9/12 lg:w-7/12"
                    >
                      Please search again
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <transition name="slide-fade" mode="out-in">
                <div v-if="paketRating">
                  <!-- <select-category @selectCategory="selectCategory" :perPage="pagination.perPage" :skip="pagination.skip"></select-category> -->
                  <div class="xl:container my-0 mx-auto px-0">
                    <div class="flex justify-left flex-wrap group">
                      <div
                        class="md:w-1/2 xl:w-1/4 md:px-2 mb-5 lg:mb-3"
                        v-for="(product, index) in getAllProducts"
                        :key="index"
                        @click.prevent="viewProduct(product.id)"
                      >
                        <product-card
                          :product="{
                            title: product.product_name,
                            id_category: product.id_category,
                            id_category_sub: product.id_category_sub,
                            titleSize:
                              'text-2xl leading-5 sm:text-lg md:text-md xl:text-[16px] xl:leading-4',
                            imgSize: 'h-[300px] md:h-[250px]',
                            inital_price: product.inital_price,
                            price: product.price,
                            discount_one: product.discount_one,
                            discount_two: product.discount_two,
                            titlePriceSize:
                              'text-[24px] leading-5 sm:text-lg md:text-md lg:text-md xl:text-[14px] xl:leading-4',
                            category: product.sub_category,
                            img: product.image ? product.image : gambar,
                            button: true,
                          }"
                        ></product-card>
                      </div>
                    </div>
                  </div>
                  <!-- <vue3-paginate :class="getDark ? 'bg-zinc-800 ml-2 text-zinc-50' : 'ml-2 text-zinc-800'"
                    :pageClasses="getDark ? 'bg-zinc-800 hover:bg-zinc-600 px-4 py-2 border border-zinc-700':'bg-zinc-50 hover:bg-zinc-300 text-zinc-800 px-4 py-2 border border-zinc-300'"
                    :activePageClasses="getDark ? 'bg-blue-800 text-white hover:bg-blue-800 px-4 py-2 border border-blue-800':'bg-blue-800 text-white hover:bg-blue-800 text-zinc-50 px-4 py-2 border border-blue-800'"
                    :totalPage=pagination.totPage :pageCount=pagination.totalItem :currentPage=pagination.currentPage
                    @pageChange="functionName">
                  </vue3-paginate> -->
                </div>
                <div v-else>
                  <div v-if="hasilCell.length > 0">
                    <div
                      :class="
                        getDark ? 'border border-zinc-800 bg-zinc-800' : 'text-gray-500'
                      "
                      class="p-4"
                    >
                      <div class="grid grid-cols-12">
                        <div class="col-span-6">
                          <h2
                            :class="getDark ? 'text-zinc-300' : 'text-gray-500'"
                            class="font-bold text-lg mb-5"
                          >
                            Pilih Bank
                          </h2>
                          <div v-for="item in hasilBank" :key="item.bank">
                            <div
                              v-if="item.notes.length > 0"
                              v-tooltip.right-end="{
                                content: () => viewLabel(item.notes),
                                html: true,
                              }"
                              class="cursor-pointer hover:bg-slate-100 inline-block px-2 py-0.5 hover:font-bold"
                            >
                              <input
                                type="checkbox"
                                @change="checkBank(item)"
                                :name="item.bank"
                                :id="item.bank"
                                class="mr-3 w-4 h-4 cursor-pointer"
                              />
                              <label
                                :class="
                                  getDark
                                    ? 'text-zinc-400  hover:text-zinc-200'
                                    : 'text-slate-500 hover:text-slate-800'
                                "
                                :for="item.bank"
                              >
                                {{ item.bank }} *
                              </label>
                            </div>
                            <div
                              v-else
                              class="cursor-pointer hover:bg-slate-100 inline-block px-2 py-0.5 hover:font-bold"
                            >
                              <input
                                type="checkbox"
                                @change="checkBank(item)"
                                :name="item.bank"
                                :id="item.bank"
                                class="mr-3 w-4 h-4 cursor-pointer"
                              />
                              <label
                                :class="
                                  getDark
                                    ? 'text-zinc-400 hover:text-zinc-200'
                                    : 'text-slate-500 hover:text-slate-800'
                                "
                                :for="item.bank"
                              >
                                {{ item.bank }}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-span-6">
                          <h2
                            :class="getDark ? 'text-zinc-300' : 'text-gray-500'"
                            class="font-bold text-lg mb-5"
                          >
                            Pilih Cell
                          </h2>
                          <div v-for="(item, i) in hasilCell" :key="i">
                            <input
                              type="checkbox"
                              @change="checkCell(item)"
                              :name="item"
                              :id="item"
                              class="mr-3 w-4 h-4"
                            />
                            <label
                              :class="
                                getDark
                                  ? 'text-zinc-400 hover:text-zinc-200'
                                  : 'text-slate-500 hover:text-slate-800'
                              "
                              :for="item"
                            >
                              {{ item }}</label
                            >
                          </div>
                        </div>
                      </div>
                      <div class="w-full mt-10">
                        <button
                          @click="addToCart"
                          class="inline-flex items-center px-4 py-2 transition ease-in-out duration-150 bg-primary text-white font-bold text-md hover:shadow-lg"
                        >
                          <svg
                            v-if="loadingSpinner"
                            class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              class="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              stroke-width="4"
                            ></circle>
                            <path
                              class="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          Add To Cart
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      v-if="hasilPaketTahunan && hasilCell.length === 0"
                      :class="
                        getDark
                          ? 'border border-zinc-800 bg-zinc-800 text-zinc-300'
                          : 'text-gray-500'
                      "
                      class="p-4"
                    >
                      <div
                        :class="getDark ? 'border-gray-600' : 'border-gray-200'"
                        class="border ml-5 flex justify-center items-center px-4 md:px-10 py-20"
                      >
                        <div
                          :class="getDark ? 'text-zinc-400' : 'text-gray-800'"
                          class="dark:bg-gray-800 p-3 flex flex-col justify-center items-center w-full"
                        >
                          <h1
                            class="text-3xl lg:text-4xl font-semibold leading-10 text-center md:w-9/12 lg:w-7/12"
                          >
                            Data Not Found
                          </h1>
                          <p
                            :class="getDark ? 'text-zinc-600' : 'text-gray-600'"
                            class="text-base leading-normal text-center md:w-9/12 lg:w-7/12"
                          >
                            Please search again
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { XIcon } from "@heroicons/vue/outline";
import ProductCard from "../components/product/CardNew.vue";
// import SelectCategory from '../components/SelectCategory.vue';

import Multiselect from "@vueform/multiselect";

import { mapGetters } from "vuex";
import axios from "axios";
import Mixin from "@/mixin/index";

// import Vue3Paginate from "vue3-paginate";

export default {
  name: "AppProduct",
  mixins: [Mixin],
  components: {
    ProductCard,
    Multiselect,
    // Pagination,
    // NavButton,
    // SelectCategory,
  },
  data() {
    return {
      xx: "tesasd <abbr> asdasd",
      gambar: `${process.env.VUE_APP_IMAGE}/img/cover.jpg`,
      paketRating: true,
      hasilPaketTahunan: false,
      loadingSpinner: false,
      loadingDataTahunan: false,
      isClear: false,
      loop: true,
      xIcon: XIcon,
      isOptionsExpanded: false,
      selectedOption: "pilih kategori",
      pilihTahun: null,
      pilihKategori: "",
      pilihBank: [],
      pilihCell: [],
      hasilBank: [],
      hasilCell: [],
      pagination: {
        totalItem: 0,
        currentPage: 1,
        perPage: 5,
        totPage: 1,
        skip: 0,
      },
    };
  },
  methods: {
    viewLabel(a) {
      const data = [];
      for (let i = 0; i < a.length; i++) {
        data.push("<li>" + a[i] + "</li>");
      }

      return data.join("");
    },
    changeCategory(id) {
      this.loadingDataTahunan = true;
      // console.log(id)
      //  this.loader();
      //   this.$emit('selectCategory')
      var params = {
        limit: this.perPage,
        skip: this.skip,
        filter_category: id,
        sort: "id DESC",
      };
      const typePencarian = {
        type: "selected",
        params: params,
      };
      this.$store.dispatch("modProducts/setSearchProducts", typePencarian);
      this.$router.push({ name: "products" });
      setTimeout(() => {
        this.loadingDataTahunan = false;
      }, 800);
    },
    selectCategory() {
      this.hasilPaketTahunan = false;
      // console.log('--------------------------')
    },
    functionName(val) {
      // this.loader();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
        passive: true,
      });
      this.pagination.skip = (val - 1) * this.pagination.perPage;
      this.pagination.currentPage = val;

      // console.log(this.pagination.skip)

      if (this.getPencarian.isSearch) {
        // console.log(this.getPencarian.keywords)
        // console.log('///////////////////////////////')
        var params = {
          limit: this.pagination.perPage,
          skip: this.pagination.skip,
          filter_product_name: this.getPencarian.keywords,
          sort: "id DESC",
        };
        const typePencarian = {
          type: "searching",
          params: params,
        };
        this.$store.dispatch("modProducts/setSearchProducts", typePencarian);
      } else {
        // console.log(this.pagination.skip)
        // console.log('+++++++++++++++++++++++')
        const params = {
          limit: this.pagination.perPage,
          skip: this.pagination.skip,
          filter_product_name: "",
          filter_tahun: "",
          filter_category: "",
          sort: "id DESC",
        };
        this.$store.dispatch("modProducts/setAllProducts", params);
      }
    },
    refresh() {
      this.paketRating = true;
      const params = {
        limit: this.pagination.perPage,
        skip: this.pagination.skip,
        filter_product_name: "",
        filter_tahun: "",
        filter_category: "",
        sort: "id DESC",
      };
      this.$store.dispatch("modProducts/setAllProducts", params);
    },
    checkBank(val) {
      if (!this.pilihBank.includes(val.bank)) {
        this.pilihBank.push(val.bank);
      } else {
        this.pilihBank.splice(this.pilihBank.indexOf(val.bank), 1); //deleting
      }
    },
    checkCell(val) {
      if (!this.pilihCell.includes(val)) {
        this.pilihCell.push(val);
      } else {
        this.pilihCell.splice(this.pilihCell.indexOf(val), 1); //deleting
      }
    },
    async addToCart() {
      if (this.pilihBank.length === 0) {
        this.loadingSpinner = true;
        setTimeout(() => {
          this.loadingSpinner = false;
          this.showAlert("error", "Silahkan pilih bank");
        }, 1000);
      } else if (this.pilihCell.length === 0) {
        this.loadingSpinner = true;
        setTimeout(() => {
          this.loadingSpinner = false;
          this.showAlert("error", "Silahkan pilih cell");
        }, 1000);
      } else {
        if (this.getAuth) {
          this.loadingSpinner = true;
          const data = {
            id_category: "1",
            id_category_sub: this.pilihKategori.toString(),
            year: this.pilihTahun,
            bank: this.pilihBank,
            cell: this.pilihCell,
          };
          // console.log(data)
          // this.$store.dispatch('modCart/addCart', data);

          var dataBody = [];
          if (data.id_category === "1") {
            dataBody = data;
          }
          if (data.id_category === "2") {
            const item = {
              id_category: "2",
              id_category_sub: data.id_category_sub,
              id_product: data.id_product,
            };
            dataBody = item;
          }
          try {
            const token = localStorage.getItem("authenticate");
            var config = {
              method: "post",
              url: `${process.env.VUE_APP_LOCAL}/v1/cart`,
              data: JSON.stringify(dataBody),
              headers: {
                Authorization: `Bearer ${JSON.parse(token).AUTH_TOKEN}`,
              },
            };
            await axios(config)
              .then((res) => {
                setTimeout(() => {
                  this.loadingSpinner = false;
                  this.$store.dispatch("modCart/setCart");
                  this.showAlert("success", "Product has been add to cart");
                  this.$router.push({ name: "checkout" });
                }, 2000);
              })
              .catch((e) => {
                this.loadingSpinner = false;
                console.log(e);
                this.showAlert("error", "Sorry, data cell not found");
              });
          } catch (e) {
            this.loadingSpinner = false;
            this.showAlert("error", "Sorry, data cell not found");
          }
        } else {
          this.loadingSpinner = true;
          setTimeout(() => {
            this.loadingSpinner = false;
            this.$swal
              .fire({
                title: "Anda belum login",
                // text: "silahkan /",
                // icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#034078",
                cancelButtonColor: "#d33",
                confirmButtonText: "Login",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.loader();
                  this.$router.push({ name: "login" });
                }
              });
          }, 2000);
        }
      }
    },
    setPaketRating(val) {
      this.loadingDataTahunan = true;
      // console.log(val)
      this.hasilPaketTahunan = false;
      this.pilihTahun = null;
      this.pilihKategori = null;
      this.hasilCell = [];
      // this.pilihCell = [];
      this.paketRating = val;
      this.$store.dispatch("modProducts/visibleSearching");
      setTimeout(() => {
        this.loadingDataTahunan = false;
      }, 800);
    },
    async searchDataTahunan() {
      this.hasilPaketTahunan = false;
      if (!this.pilihTahun) {
        this.showAlert("error", "Silahkan pilih tahun");
      } else if (!this.pilihKategori) {
        this.showAlert("error", "Silahkan pilih kategori");
      } else {
        this.loadingDataTahunan = true;

        const pencarian = this.pilihTahun.toString();
        const kategori = this.pilihKategori.toString();
        await axios
          .get(
            `${process.env.VUE_APP_LOCAL}/v1/product/rating-tahunan?filter_tahun=${pencarian}&filter_category=${kategori}`
          )
          .then((res) => {
            setTimeout(() => {
              if (res.data.result.bank === null) {
                this.hasilBank = [];
                this.hasilCell = [];
              } else {
                this.hasilBank = res.data.result.bank;
                this.hasilCell = res.data.result.cell;
              }
              this.hasilPaketTahunan = true;
              this.loadingDataTahunan = false;
            }, 2000);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  mounted() {
    this.loadingDataTahunan = true;
    setTimeout(() => {
      this.loadingDataTahunan = false;
    }, 800);
  },
  created() {
    // const totProd = localStorage.getItem('totProd');
    this.pagination.totalItem = this.getTotalWaitingTransaction;
    this.pagination.totPage = Math.ceil(
      this.getTotalWaitingTransaction / this.pagination.perPage
    );

    if (this.getAllProducts.length === 0) {
      // console.log('XXXXXXXXXXXXXXXXXXXXX')
      const params = {
        limit: this.pagination.perPage,
        skip: this.pagination.skip,
        filter_product_name: "",
        filter_tahun: "",
        filter_category: "",
        sort: "created_at DESC",
      };
      this.$store.dispatch("modProducts/setAllProducts", params);
    }
  },
  watch: {
    wow(newCount, oldCount) {
      if (newCount) {
        this.pagination.currentPage = 1;
      }
      // this.paketRating = newCount;
      // this.hasilPaketTahunan = newCount;
    },
  },
  computed: {
    ...mapGetters({
      getProductSearch: "getProductSearch",
      getTahunProduct: "getTahunProduct",
      getCategoryPaketRating: "modKategori/getCategoryPaketRating",
      getKinerjaKeuangan: "modKategori/getKinerjaKeuangan",
      getAllProducts: "modProducts/getAllProducts",
      getPencarian: "modProducts/getPencarian",
      getAuth: "getAuth",
      getDark: "getDark",
      getTotalWaitingTransaction: "modTransaksi/getTotalWaitingTransaction",
    }),
    wow() {
      return this.$store.getters["modProducts/getPencarian"].isSearch;
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.5s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}

.slide-enter-active {
  transition: all 0.3s ease;
}

.slide-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-enter,
.slide-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
