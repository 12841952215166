import {Form as VeeForm, Field as VeeField, defineRule, ErrorMessage, configure } from 'vee-validate'
import { required, integer, email, min, max, alpha_spaces as alphaSpaces, min_value as MinVal, max_value as MaxVal, confirmed } from '@vee-validate/rules';
export default{
    install(app){
        app.component('VeeForm', VeeForm);
        app.component('VeeField', VeeField);
        app.component('ErrorMessage', ErrorMessage);

        defineRule('required', required);
        defineRule('integer', integer);
        defineRule('email', email);
        defineRule('min', min);
        defineRule('max', max);
        defineRule('alpha_spaces', alphaSpaces); 
        defineRule('min_value', MinVal); 
        defineRule('max_value', MaxVal); 
        defineRule('confirmed', confirmed); 

        configure({
            generateMessage:(ctx) =>{
                const messages = {
                  required: `The field ${ctx.field} is required`,
                };

                const message = messages[ctx.rule.name]
                 ? messages[ctx.rule.name]
                 : `the field ${ctx.field} is invalid`;

                 return message;
            },

            validateOnBlur: true,
            validateOnChange: true,
            validateOnInput: true,
            validateOnModelUpdate: true,
        });
    }
}