
  import {
    BookmarkAltIcon,
    CalendarIcon,
    ChartBarIcon,
    SupportIcon,
  } from '@heroicons/vue/outline';

export default {
  namespaced: true,
  state: {
    menuProfile: [
      {
        name: "Profile",
        href: "profile",
        icon: ChartBarIcon,
      },
      {
        name: "Ubah Password",
        href: "form-reset-password",
        icon: ChartBarIcon,
      },
    ],
    menuUtama: [
      {
        name: "Home",
        href: "home",
        icon: SupportIcon,
      },
      {
        name: "About",
        href: "about",
        icon: BookmarkAltIcon,
      },
      {
        name: "Products",
        href: "products",
        icon: CalendarIcon,
      },
      {
        name: "Contact",
        href: "contact",
        icon: CalendarIcon,
      },
    ],
    menuAuth: [
      {
        name: "My Account",
        active: true,
        link: "profile",
        icon: "fa-address-card",
        total: 0,
      },
      {
        name: "Transaction",
        active: false,
        link: "transaction",
        icon: "fa-list-check",
        total: 0,
      },
      {
        name: "Order",
        active: false,
        link: "order",
        icon: "fa-basket-shopping",
        total: 0,
      },
    ],
  },
  getters: {
    getMenuProfile: (state) => {
      return state.menuProfile;
    },
    getMenuUtama: (state) => {
      return state.menuUtama;
    },
    getMenuAuth: (state, rootGetters, getters) => {
      const totWaiting = getters.modTransaksi.totalWaitingTransaction;
      const totComplete = getters.modTransaksi.totalCompletedTransaction;
      for (let i = 0; i < state.menuAuth.length; i++) {
        if (state.menuAuth[i].link === "transaction") {
          state.menuAuth[i].total = totWaiting;
        }
        if (state.menuAuth[i].link === "order") {
          state.menuAuth[i].total = totComplete;
        }
      }
      return state.menuAuth;
    },
  },
  mutations: {
    updateMenu(state, data) {
      // console.log(data); 
      state.menuAuth[data.id].active = data.aktif;
      // console.log(state.menuAuth[data.id]);
    },
  },
  actions: {
    setAktifMenu({ commit, state }, data) {
      for (let i = 0; i < state.menuAuth.length; i++) {
        if (state.menuAuth[i].link === data) {
          const qry = {
            id: i,
            aktif: true,
          };
          commit("updateMenu", qry);
          // state.menuAuth[i].active = true;
          // state.$router.push({ name: val });
        } else {
          const qry = {
            id: i,
            aktif: false,
          };
          commit("updateMenu", qry);
          // state.menuAuth[i].active = false;
        }
      }
    },
  },
};