export default {
    namespaced: true,
    state: {
        userProfile: [],
        company: {
          email:'info@domain.com',
          phone:'0813-8413-3713',
          address:'Jl. Arteri Pd. Pinang No.12A, Kby. Lama Utara, Kec. Kby. Lama, Kota Jakarta Selatan Daerah Khusus Ibukota Jakarta 12220',
          googleMap:'https://www.google.com/maps/dir//Majalah+Infobank+Jl.+Arteri+Pd.+Pinang+No.12A+RT.5%2FRW.1,+Kby.+Lama+Utara+Kec.+Kby.+Lama,+Kota+Jakarta+Selatan,+Daerah+Khusus+Ibukota+Jakarta+12220/@-6.2379636,106.7829656,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x2e69f13d4bc00243:0xab8aa41d02d005a2!2m2!1d106.7829656!2d-6.2379636'
        },
    },
    getters: {
        getCompany: (state) => {
            return state.company;
        },
        getUserProfile: (state) => {
        const data = state.userProfile;
        return data;
        },
    },
    mutations: {
        updateUserProfile(state, data) {
          state.userProfile.push(JSON.parse(data));
        },
    },
    actions: {
        setUserProfile({ commit }, data) {
          commit('updateUserProfile', data);
        },
    }

}