
import axios from 'axios';
import modKategori from './category';

export default {
    namespaced: true,
    state: {
        allProducts: [],
        newProduct: [],
        similiarProduct: [],
        singleProduct: [],
        pencarian: {
          isSearch: false,
          total: 0,
          type: '',
          keywords: '',
          data: [],
        },
    },
    getters: {
      getAllProducts: (state) => {
          return state.allProducts;
      },
      getNewProducts: (state) => {
          return state.newProduct;
      },
      getSimiliarProducts: (state) => {
          return state.similiarProduct;
      },
      getSingleProducts: (state) => {
          return state.singleProduct;
      },
      getPencarian: (state) => {
          return state.pencarian;
      },
    },
    mutations: {
      UPDATE_ALL_PRODUCTS(state, data) {
        state.allProducts = data;
        state.pencarian.isSearch = false;
      },
      UPDATE_NEW_PRODUCTS(state, data) {
        state.newProduct = data;
      },
      UPDATE_SIMILIAR_PRODUCTS(state, data) {
        state.similiarProduct = data;
      },
      UPDATE_SINGLE_PRODUCT(state, data) {
        state.singleProduct = data;
        state.pencarian.isSearch = false;
      },
      UPDATE_PENCARIAN(state, data) {
        state.pencarian = data;
        // state.pencarian.data = data;
        // state.pencarian.isSearch = true;
      },
      VISIBLE_SEARCHING(state) {
        const defaults =  {
          isSearch: false,
          type: '',
          keywords: '',
          data: [],
        };
        state.pencarian = defaults;
        // state.pencarian.data = [];
        // state.pencarian.isSearch = false;
      },
    },
    actions: {
      visibleSearching({ commit }) {
        commit('VISIBLE_SEARCHING');
      },

      async setAllProducts({ commit, dispatch }, data) {
        try {
          var config = {
            method: 'get',
            url: `${process.env.VUE_APP_LOCAL}/v1/product/paket-rating`,
            params: data,
          };
          
          await axios(config)
   
          .then((res) => {
            const totalProduct = res.data._metadata.count; 
            localStorage.setItem('totProd', totalProduct);
            const qryAllProducts = [];
            const qryNewProducts = [];
            for (let i = 0; i < res.data.result.length; i++) {

              const prodName = res.data.result[i]["product_name"];
              
        const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
        const to = 'aaaaaeeeeeiiiiooooouuuunc------';
        const newText = prodName.split('').map(
          (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)));
    
        const slugy = newText
          .toString()                     // Cast to string
          .toLowerCase()                  // Convert the string to lowercase letters
          .trim()                         // Remove whitespace from both sides of a string
          .replace(/\s+/g, '-')           // Replace spaces with -
          .replace(/&/g, '-y-')           // Replace & with 'and'
          .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
          .replace(/\-\-+/g, '-');        // Replace multiple - with single -


              if (i < 5) {
                qryNewProducts.push({
                  id: res.data.result[i]["id"],
                  sub_category: res.data.result[i]["sub_category"],
                  product_name: prodName,
                  inital_price: res.data.result[i]["inital_price"],
                  discount_price: res.data.result[i]["discount_price"],
                  price: res.data.result[i]["price"],
                  discount_one: res.data.result[i]["discount_one"],
                  discount_two: res.data.result[i]["discount_two"],
                  description: res.data.result[i]["description"],
                  tahun: res.data.result[i]["tahun"],
                  image: res.data.result[i]["image"],
                  created_at: res.data.result[i]["created_at"],
                  slug: slugy,
                });
              } 
                qryAllProducts.push({
                  id: res.data.result[i]["id"],
                  sub_category: res.data.result[i]["sub_category"],
                  product_name: prodName,
                  inital_price: res.data.result[i]["inital_price"],
                  discount_price: res.data.result[i]["discount_price"],
                  price: res.data.result[i]["price"],
                  discount_one: res.data.result[i]["discount_one"],
                  discount_two: res.data.result[i]["discount_two"],
                  description: res.data.result[i]["description"],
                  tahun: res.data.result[i]["tahun"],
                  image: res.data.result[i]["image"],
                  created_at: res.data.result[i]["created_at"],
                  slug: slugy,
                });
            }; 
            commit('UPDATE_ALL_PRODUCTS', qryAllProducts);
            commit('UPDATE_NEW_PRODUCTS', qryNewProducts);
          })
          .catch((e) => console.log(e));
        } catch (error) {
          console.log('terjadi kesalahan server all products');
        };
      },
      

      async setSearchProducts({ commit }, data) {
        try {
          // var params = {
          //     limit: 100,
          //     skip: 0,
          //     filter_product_name: data,
          //     sort: 'id DESC',
          // };
          // console.log(data)
 
          var config = {
            method: 'get',
            url: `${process.env.VUE_APP_LOCAL}/v1/product/paket-rating`,
            params: data.params,
          };
          
          await axios(config)
   
          .then((res) => {
              console.log(res.data._metadata.count)
            if (res.data.result) { 
              const qrySearching = [];
              for (let i = 0; i < res.data.result.length; i++) {
                  qrySearching.push({
                    id: res.data.result[i]["id"],
                    sub_category: res.data.result[i]["sub_category"],
                    id_category: res.data.result[i]["id_category"],
                    id_category_sub: res.data.result[i]["id_category_sub"],
                    product_name: res.data.result[i]["product_name"],
                    inital_price: res.data.result[i]["inital_price"],
                    discount_price: res.data.result[i]["discount_price"],
                    price: res.data.result[i]["price"],
                    discount_one: res.data.result[i]["discount_one"],
                    discount_two: res.data.result[i]["discount_two"],
                    description: res.data.result[i]["description"],
                    tahun: res.data.result[i]["tahun"],
                    image: res.data.result[i]["image"],
                    created_at: res.data.result[i]["created_at"],
                  });
              };
              const pencarian =  {
                isSearch: true,
                type: data.type,
                total: res.data._metadata.count,
                keywords: data.params.filter_product_name,
                data: qrySearching,
              };
              
              commit('UPDATE_PENCARIAN', pencarian);
            } else {
              
              const pencarian =  {
                isSearch: true,
                total: res.data._metadata.count,
                type: '',
                keywords: '',
                data: [],
              };
              commit('UPDATE_PENCARIAN', pencarian);
            }
          })
          .catch((e) => console.log(e));
        } catch (error) {
          console.log('terjadi kesalahan server all products');
        };
      },

      async setDetailProduct({ commit, dispatch }, id) {
        try {
          var config = {
            method: 'get',
            url: `${process.env.VUE_APP_LOCAL}/v1/product/paket-rating/${id}`,
          };
          await axios(config)
   
          .then((res) => {
            const data = {
              id: res.data.result.id,
              sub_category: res.data.result.sub_category,
              id_category: res.data.result.id_category,
              id_category_sub: res.data.result.id_category_sub,
              product_name: res.data.result.product_name,
              inital_price: res.data.result.inital_price,
              discount_price: res.data.result.discount_price,
              price: res.data.result.price,
              discount_one: res.data.result.discount_one,
              discount_two: res.data.result.discount_two,
              description: res.data.result.description,
              tahun: res.data.result.tahun,
              image: res.data.result.image,
              created_at: res.data.result.created_at,
            };
 
            commit('UPDATE_SINGLE_PRODUCT', data);
            dispatch('setSimiliarProducts', res.data.result.id_category_sub)
          })
          .catch((e) => console.log(e));
        } catch (error) {
          console.log('terjadi kesalahan server');
        };
      },
      
      async setSimiliarProducts({ commit, rootGetters }, nmCategory) { //modKategori
        // var idKategori = 0;
        // const qryCategory = rootGetters['modKategori/getCategory'];
        // for (let i = 0; i < qryCategory.length; i++) {
        //   if (qryCategory[i].id_category_sub === nmCategory) {
        //     idKategori = qryCategory[i].id
        //   }
          
        // } 
        try {
          var params = {
              limit: 5,
              skip: 0,
              filter_category:nmCategory,
              sort: 'id DESC',
          };

          var config = {
            method: 'get',
            url: `${process.env.VUE_APP_LOCAL}/v1/product/paket-rating`,
            params: params,
          };
          
          await axios(config)
   
          .then((res) => {
            const qrySimiliar = [];
            for (let i = 0; i < res.data.result.length; i++) {
              qrySimiliar.push({
                id: res.data.result[i]["id"],
                sub_category: res.data.result[i]["sub_category"],
                product_name: res.data.result[i]["product_name"],
                inital_price: res.data.result[i]["inital_price"],
                discount_price: res.data.result[i]["discount_price"],
                price: res.data.result[i]["price"],
                discount_one: res.data.result[i]["discount_one"],
                discount_two: res.data.result[i]["discount_two"],
                description: res.data.result[i]["description"],
                tahun: res.data.result[i]["tahun"],
                image: res.data.result[i]["image"],
                created_at: res.data.result[i]["created_at"],
              });
            };
            commit('UPDATE_SIMILIAR_PRODUCTS', qrySimiliar);
          })
          .catch((e) => console.log(e));
        } catch (error) {
          console.log('terjadi kesalahan server similiar products');
        };
      },
    }

}