<template>
    <div v-if="loadingSpinner">
        <div :class="getDark ? 'border border-zinc-800 bg-zinc-800 text-zinc-300' : 'text-gray-500'" class="p-4">
            <div :class="getDark ? 'border-gray-600' : 'border-gray-200'"
                class="ml-5 flex justify-center items-center px-4 md:px-10 py-20">
                <div :class="getDark ? 'text-zinc-400' : 'text-gray-400'"
                    class="dark:bg-gray-800 p-3 flex flex-col justify-center items-center w-full">
                    <svg class="w-10 h-10 text-gray-500 animate-spin left-1/2 mr-2" fill="none" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                        </circle>
                        <path class="opacity-75"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            fill="currentColor" />
                    </svg>
                    <h1 class="text-xl font-semibold leading-10 text-center md:w-9/12 lg:w-7/12">
                        Loading</h1>
                </div>
            </div>
        </div>
    </div>

    <div v-else>
        <!-- Form  -->
        <vee-form @submit="updateProfile" :validation-schema="profileSchema">
            <div class="mt-4">
                <global-input v-model="fullname" label="Full Name" type="text" nama="fullname">
                </global-input>
            </div>
            <div class="mt-4">
                <global-input v-model="email" label="Email Address" type="email" nama="email">
                </global-input>
            </div>
            <div class="mt-4">
                <global-input v-model="phone" label="No Phone" type="text" nama="phone">
                </global-input>
            </div>
            <div class="grid grid-cols-8 w-full mt-10">
                <div class="col-span-1">
                <button class="w-full px-4 py-2 tracking-wide text-white transition-colors
							duration-200 transform bg-gray-700 rounded hover:bg-primary focus:outline-none focus:bg-primary"
                    v-if="loadingSpinner">
                    <global-loading></global-loading>
                </button>
                <button v-else class="w-full px-4 py-2 tracking-wide text-white transition-colors
								duration-200 transform bg-gray-700 rounded hover:bg-primary
								focus:outline-none focus:bg-primary hover:cursor-pointer">
                    Update
                </button>
            </div>
            </div>

        </vee-form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GlobalInput from '@/components/GlobalInput.vue';
import GlobalLoading from '@/components/GlobalLoading.vue';
import Mixin from '@/mixin/index';
import axios from 'axios';

export default {
    name: "Biodata",
    mixins: [Mixin],
    components: {
        GlobalInput,
        GlobalLoading,
    },
    data() {
        return {
            profileSchema: {
                fullname: 'required',
                email: 'required|email',
                phone: 'required',
            },
            fullname: '',
            email: '',
            phone: '',
            loadingSpinner: false,
        };
    },
    methods: {
        getAvatarName(name) {
            const words = name.split(' ');
            for (let i = 0; i < words.length; i++) {
                words[i] = words[i][0].toUpperCase() + words[i].substr(1);
            };
            const nama = words.join(' ');
            return nama;
        },
        async updateProfile() {

            this.loadingSpinner = true;
            const token = localStorage.getItem("authenticate");
            const data = {
                'fullname': this.fullname,
                'email': this.email,
                'phone': this.phone,
            };
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${JSON.parse(token).AUTH_TOKEN}`,
                },
                withCredentials: true,
            };
            try {
                await axios.post(`${process.env.VUE_APP_LOCAL}/v1/profile`, data, config)
                    .then(res => {
                        const payload = res.data;

                        const avatar = `https://ui-avatars.com/api/?name=${this.getAvatarName(payload.result.Fullname)}`;
                        const profile = {
                            'email': payload.result.Email,
                            'fullname': payload.result.Fullname,
                            'id': payload.result.ID,
                            'phone': payload.result.Phone,
                        };
                        const arrLogin = {
                            'AUTH_TOKEN': JSON.parse(token).AUTH_TOKEN,
                            'profile': profile,
                            'avatar': avatar,
                        };

                        setTimeout(() => {
                            this.loadingSpinner = false;
                            this.$store.dispatch('setAuthenticate', JSON.stringify(arrLogin));
                            this.$store.dispatch('modTransaksi/setAllTransaction');
                            this.$store.dispatch('modCart/setCart');
                            this.showAlert('success', 'Update Successfully');
                        }, 2000);

                        // } else {
                        // 	this.loadingSpinner = false;
                        // 	this.showAlert('error', 'Invalid username or password');
                        // };
                    })
                    .catch(err => {
                        this.loadingSpinner = false;
                        this.showAlert('error', 'Invalid Data');
                    });
            } catch (error) {
                this.loadingSpinner = false;
                this.showAlert('error', 'Network Error');
            };
        }
    },
    // async mounted() {
    //     const data = await JSON.parse(localStorage.getItem('authenticate'));
    //     // console.log(data.profile)
    //             this.fullname = data.profile.fullname;
    //             this.email = data.profile.email;
    //             this.phone = data.profile.phone;
    // },
    watch: {
        getProfile() {
            console.log('first')
        }
    },
    computed: {
        ...mapGetters({
            getDark: 'getDark',
        }),
        getProfile() {
            const data = JSON.parse(localStorage.getItem('authenticate'));
            // console.log(data.profile)
            this.fullname = data.profile.fullname;
            this.email = data.profile.email;
            this.phone = data.profile.phone;
            this.$store.dispatch('modTransaksi/setAllTransaction');
            this.showLoading();
        },
    },
    created() {
        this.$store.dispatch('modTransaksi/setAllTransaction');
        this.showLoading();
    },
}
</script>