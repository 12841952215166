<template>
  <div v-if="getAuthenticate">
    <!-- <transition> -->
    <!-- <section class="bg-gray-100"> -->
    <div class="max-w-3xl mx-auto py-0 md:py-5" id="printMe">
      <article class="shadow-none md:shadow-md overflow-hidden">
        <div :class="getDark ? 'bg-zinc-800':'bg-white'" class="md:rounded-b-md ">
          <div class="p-9 border-gray-200">
            <div class="space-y-6">
              <div class="flex justify-center items-center w-full">
                <span :class="getDark ? 'text-zinc-300':'text-zinc-800'" class="font-bold text-4xl"> Invoice </span>
              </div>
              <div class="flex justify-between items-top">
                <div class="space-y-4">
                  <div>
                    <img class="h-6 -ml-2 object-cover mb-4" :src="logo">
                  </div>
                  <div>
                    <p :class="getDark ? 'text-zinc-300':'text-gray-400'" class="font-medium text-sm"> Billed To </p>
                    <div :class="getDark ? 'text-zinc-500':'text-zinc-800'">
                      <p> {{ profile.fullname }} </p>
                      <p> {{ profile.email }} </p>
                      <p> {{ profile.id }} </p>
                    </div>
                  </div>
                </div>
                <div class="space-y-2">
                  <div>
                    <p :class="getDark ? 'text-zinc-300':'text-gray-400'" class="font-medium text-sm"> Invoice Number
                    </p>
                    <p :class="getDark ? 'text-zinc-500':'text-zinc-800'"> {{ getInvoice.code }} </p>
                  </div>
                  <div>
                    <p :class="getDark ? 'text-zinc-300':'text-gray-400'" class="font-medium text-sm"> Invoice Date </p>
                    <p :class="getDark ? 'text-zinc-500':'text-zinc-800'"> {{ getInvoice.created_at }} </p>
                  </div>
                  <!-- <div>
                    <p class="font-medium text-sm text-gray-400"> ABN </p>
                    <p> 57 630 182 446 </p>
                  </div>  -->
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="p-9 border-b border-gray-200">
            <p class="font-medium text-sm text-gray-400"> Note </p>
            <p class="text-sm"> Thank you for your order. </p>
          </div> -->
          <div class="px-4">
            <!-- <div class="px-9 border-b border-gray-200"></div> -->
            <table :class="getDark ? 'w-full border border-zinc-600 divide-y divide-zinc-600'
            :'w-full border border-gray-200 divide-y divide-gray-200'" class="text-sm">
              <thead>
                <tr>
                  <th scope="col" :class="getDark ? 'text-zinc-200':'text-zinc-800'"
                    class="px-9 py-4 text-left font-semibold"> Item </th>
                  <th scope="col" class="py-3 text-left font-semibold text-gray-400"> </th>
                  <th scope="col" class="py-3 text-left font-semibold text-gray-400"> </th>
                  <th scope="col" class="py-3 text-left font-semibold text-gray-400"></th>
                  <th scope="col" :class="getDark ? 'text-zinc-200':'text-zinc-800'"
                    class="px-9 py-4 text-left font-semibold">price </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <tr>
                  <td class="px-9 py-5 whitespace-nowrap space-x-1 flex items-center">
                    <div>
                      <p :class="getDark ? 'text-zinc-200':'text-zinc-800'" class="text-[16px]"> {{
                      getInvoice.product_name }} </p>
                      <p :class="getDark ? 'text-zinc-400':'text-zinc-400'" class="text-[12px]"> {{
                      getInvoice.category_sub }} </p>
                    </div>
                  </td>
                  <td class="whitespace-nowrap text-gray-600 truncate"></td>
                  <td class="whitespace-nowrap text-gray-600 truncate"></td>
                  <td class="whitespace-nowrap text-gray-600 truncate"> </td>
                  <td :class="getDark ? 'text-zinc-200':'text-zinc-800'"
                    class="whitespace-nowrap font-bold text-center"> {{ formatRupiah(getInvoice.price) }} </td>
                </tr>
              </tbody>
            </table>
            <!-- <div class="px-9 border-b border-gray-200"></div> -->
          </div>
          <div class="p-9">
            <div class="space-y-3">
              <div :class="getDark ? 'text-zinc-200':'text-zinc-800'" class="flex justify-between">
                <div>
                  <p class="text-lg font-bold"> Total </p>
                </div>
                <p class="text-lg font-bold"> {{ formatRupiah(getInvoice.price) }} </p>
              </div>
            </div>
          </div>
          <!-- <div class="p-9 border-b border-gray-200">
            <div class="space-y-3">
              <div class="flex justify-between">
                <div>
                  <p class="font-bold text-black text-lg"> Amount Due </p>
                </div>
                <p class="font-bold text-black text-lg"> $360.00 </p>
              </div>
            </div>
          </div> -->
        </div>
      </article>
    </div>
    <div class="flex justify-center items-center w-full py-4">
      <button v-print="'#printMe'" class="px-4 py-2 border mb-2 bg-white mx-1 shadow-md hover:bg-gray-100">
        <i class="fa-solid fa-print"></i> Print</button>
      <!-- <button v-if="getInvoice.is_download" @click="download(getInvoice.id)"
        class="px-4 py-2 border mb-2 bg-white mx-1 shadow-md hover:bg-gray-100">
        <i class="fa-solid fa-print disabled"></i> Download</button> -->
      <button @click="goTo" class="px-4 py-2 border mb-2 bg-white mx-1 shadow-md hover:bg-gray-100">
        <i class="fa-solid fa-print"></i> Collection</button>
    </div>
    <!-- </section> -->
    <!-- </transition> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Mixin from '@/mixin/index';
import print from 'vue3-print-nb'

export default {
  name: "Transaction",
  mixins: [Mixin],
  directives: {
    print
  },
  data() {
    return {
      profile: {}
    }
  },
  methods: {
    // download(id) { 
    //       this.$swal.fire({
    //         title: '<strong>Perhatian</strong>',
    //         icon: 'info',
    //         html:
    //           'Anda hanya memiliki kesempatan 1 kali dalam pengunduhan file ini, <br><br>',
    //         showCloseButton: false,
    //         showCancelButton: false,
    //         focusConfirm: false,
    //         showCancelButton: true,
    //         confirmButtonColor: '#034078',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Download Files'
    //       }).then((result) => {
    //         if (result.isConfirmed) {
    //           window.open(`${process.env.VUE_APP_LOCAL}/v1/download/${id}`, '_blank');
    //           this.$router.push({ name: 'order' });
    //         }
    //       })
    // },
    goTo() {
      this.loader();
      this.$router.push({ name: 'order' });
    }
  },
  created() {
    if (this.$route.params.id) {
      setTimeout(() => {
        this.$store.dispatch('modTransaksi/setInvoice', this.$route.params.id);
      }, 2000);
      const locStrorage = localStorage.getItem('authenticate');
      this.profile = JSON.parse(locStrorage).profile;
    }
  },
  beforeMount() {

  },
  mounted() {
    this.loader();
  },
  computed: {
    ...mapGetters({
      getAuthenticate: 'getAuthenticate',
      getInvoice: 'modTransaksi/getInvoice',
      getDark: 'getDark',
      logo: "logo"
    })
  }
}
</script>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>