<template>
  <div
    :class="
      getDark
        ? 'bg-zinc-700 border-none'
        : 'border border-t-0 border-slate-200 rounded-lg shadow-lg lg:shadow-sm'
    "
    class="relative pb-4 ease-in-out group-hover:duration-500 overflow-hidden group-hover:cursor-pointer transition duration-300 transform hover:-translate-y-2 hover:shadow-2xl"
  >
    <div class="flex flex-wrap" :title="product.title">
      <!-- images  -->
      <!-- <div :class="product.imgSize ? product.imgSize : 'h-[150px] md:h-[200px]'" class="relative w-full overflow-hidden"> -->
      <div class="relative w-full overflow-hidden">
        <!-- likes  -->
        <!-- <div class="bg-transparent absolute overflow-hidden w-10 h-10 flex justify-center items-center
                rounded-full right-3 top-3 duration-500 ease-in-out p-3">
                    <i class="fa-solid opacity-100 fa-heart text-2xl text-white hover:text-red-600"></i>
                </div> -->

        <!-- Discount 1 -->
        <div
          v-if="product.discount_one > 0"
          :class="product.discount_two > 0 ? 'right-20' : 'right-3'"
          class="absolute overflow-hidden w-16 h-16 flex justify-center rounded-full z-50 top-0 font-bold text-md duration-500 ease-in-out"
        >
          <span class="w-full top-2 text-[14px] absolute text-center text-white"
            >{{ product.discount_one }}%</span
          >
          <span class="w-full top-6 text-[12px] absolute text-center text-white"
            >Off</span
          >
        </div>
        <img
          v-if="product.discount_one > 0"
          src="@/assets/discount_tag.png"
          alt="product"
          :class="product.discount_two > 0 ? 'right-20' : 'right-3'"
          class="z-0 absolute overflow-hidden w-16 h-2w-16 top-0"
        />
        <!-- ----------------------------  -->
        <!-- Discount 2  -->
        <div
          v-if="product.discount_two > 0"
          class="absolute overflow-hidden w-16 h-16 flex justify-center rounded-full right-3 z-50 top-0 font-bold text-md duration-500 ease-in-out"
        >
          <span class="w-full top-2 text-[14px] absolute text-center text-white"
            >{{ product.discount_two }}%</span
          >
          <span class="w-full top-6 text-[12px] absolute text-center text-white"
            >Off</span
          >
        </div>
        <img
          v-if="product.discount_two > 0"
          src="@/assets/discount_tag.png"
          alt="product"
          class="right-3 z-0 absolute overflow-hidden w-16 h-2w-16 top-0"
        />
        <!-- ----------------------------  -->

        <!-- ----------------------------  -->

        <!-- <img v-if="product.discount_one > 0" src="@/assets/discount.png" alt="product"
                    class="bottom-[20%] absolute overflow-hidden group-hover:ease-in ease-out duration-500 object-center z-30 w-[80%] h-auto" /> -->

        <!-- price  -->
        <!-- <div class="bg-slate-300 absolute overflow-hidden w-auto h-10
                flex justify-center items-center right-0 bottom-3 duration-500 ease-in-out
                border-l-8 border-yellow-500 p-3"
                v-if="product.price">
                    <span class="font-bold font-lg">{{ formatRupiah(product.price) }}</span>
                </div> -->
        <!-- <img :src="'http://core.infobankdata.octarine.id/img/cover.jpg'" :alt="product.title" class="object-cover
                group-hover:duration-500 group-hover:ease-in ease-out duration-500 object-center w-full h-auto"/> -->

        <!-- category  -->
        <div
          v-if="product.sub_category"
          class="absolute flex bg-yellow-300 text-gray-700 bottom-0 justify-center"
          style="transform: translate(-50%, -0%); left: 50%"
        >
          <div
            class="py-1 px-3 group-hover:bg-orange-500 text-gray-600 group-hover:text-white"
          >
            <span
              :class="product.sub_categorySize ? 'text-[16px] font-semibold' : 'text-xs'"
            >
              <!-- <span class="text-2xl font-bold"> -->
              {{ product.sub_category }}
            </span>
          </div>
        </div>
        <img
          :src="product.img"
          :alt="product.title"
          class="object-cover mt-1 group-hover:duration-500 group-hover:ease-in ease-out duration-500 object-center h-full w-full"
        />
      </div>
      <div class="w-full md:h-[45px] px-4 pt-0 sm:pt-4 leading-5">
        <div
          class="font-bold md:font-semibold text-left"
          :class="[
            product.titleSize ? 'text-[16px]' : 'text-[14px]',
            getDark ? 'text-slate-400' : 'text-slate-600',
          ]"
        >
          {{ product.title }}
        </div>
      </div>
      <!-- content  -->
      <div class="px-4 py-2" v-if="product.content">
        <div
          class="flex justify-between items-center flex-row w-full no-underline text-slate-500 text-left text-sm"
          v-html="desciption"
        ></div>
      </div>
      <!-- rating  -->
      <div class="w-full px-4 pt-0 sm:pt-4">
        <div class="grid grid-cols-12 items-center">
          <!-- <div class="col-span-12 sm:col-span-4 mb-2 sm:mb-0 justify-start items-center">
                        <div class="flex justify-start">
                            <svg class="w-6 h-6 sm:w-8 sm:h-8 md:w-6 md:h-6 text-yellow-400" fill="currentColor"
                                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                                </path>
                            </svg>
                            <p class="ml-2 text-lg sm:text-[14px]" :class="getDark ? 'text-white' : 'text-red-900' ">
                                {{ product.rate ? product.rate : 0 }}
                            </p>
                        </div>
                    </div> -->
          <div v-if="product.discount_one > 0" class="col-span-6">
            <div
              v-if="getDark ? 'text-white' : 'text-red-900'"
              class="text-[14px] text-red-300 text-center font-medium px-0 py-2 sm:py-3 md:px-0 md:py-1 rounded-lg line-through float-left"
            >
              {{ formatRupiah(product.inital_price) }}
            </div>
          </div>
          <div :class="product.discount_one > 0 ? 'col-span-6' : 'col-span-12 mb-4'">
            <!-- v-if="product.price" -->
            <div
              :class="[
                product.price > 15000000
                  ? 'text-green-800'
                  : product.price > 0
                  ? 'text-green-800'
                  : 'text-red-500 font-bold',
                // product.discount_one > 0 ? 'text-[18px]' : 'text-[18px]',
                getDark
                  ? 'text-white'
                  : product.price > 0
                  ? 'text-red-900'
                  : 'text-red-500',
                product.discount_one > 0 ? 'float-right' : 'float-right',
              ]"
              class="text-[18px] text-right inline-block font-medium px-0 py-2 sm:py-3 md:px-0 md:py-1 rounded-lg"
            >
              <!-- {{ formatRupiah(product.price) }} -->
              {{ product.price > 0 ? formatRupiah(product.price) : "FREE" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Card",
  props: {
    product: Object,
  },
  computed: {
    desciption() {
      const konten = this.limitStr(this.product.content, 100);
      return konten;
    },
  },
  methods: {
    limitStr(string, limit) {
      let str = string;
      if (typeof str === "string" && str.length > limit) {
        str = str.slice(0, limit) + " ...";
      }
      return str;
    },
    formatRupiah(val) {
      const format = val.toString().split("").reverse().join("");
      const convert = format.match(/\d{1,3}/g);
      const rupiah = "Rp " + convert.join(".").split("").reverse().join("");
      return rupiah;
    },
  },
  computed: {
    ...mapGetters({
      getDark: "getDark",
    }),
  },
};
</script>
