import axios from 'axios';
import modKategori from './category';

export default {
  namespaced: true,
  state: {
    cart: [],
  },
  getters: {
    getCart: (state) => {
      return state.cart;
    },
  },
  mutations: {
    UPDATE_CART(state, data) {
      state.cart = data;
    },
    DELETE_CART(state, data) {
      state.cart = data;
    },
  },
  actions: {
    logoutCart({ commit, dispatch }) {
      commit("UPDATE_CART", []);
    },

    async addCart({ rootGetters, dispatch }, data) {
      var dataBody = [];
      if (data.id_category === "1") { 
        dataBody = data;
      }
      if (data.id_category === "2") { 
        const item = {
          id_category: "2",
          id_category_sub: data.id_category_sub,
          id_product: data.id_product,
        };
        dataBody = item;
      } 
      try {
        const token = localStorage.getItem("authenticate");
        var config = {
          method: "post",
          url: `${process.env.VUE_APP_LOCAL}/v1/cart`,
          data: JSON.stringify(dataBody),
          headers: {
            Authorization: `Bearer ${JSON.parse(token).AUTH_TOKEN}`,
          },
        };
        await axios(config)
          .then((res) => {
            if (res.data.result) {
              
              dispatch("setCart");
            }else{
              dispatch('setShowAlert', {
                message: 'tess',
                color: 'bg-red-300',
              });
            }
              
          })
          .catch((e) => {
            dispatch('setShowAlert', {
              message: 'Sorry, data cell not found',
              color: 'bg-red-300',
            }, { root: true });
          });
      } catch (error) {
        console.log("terjadi kesalahan server");
      }
    },

    async setCart({ commit, dispatch }) { 
      try {
        const token = localStorage.getItem("authenticate");
        var config = {
          method: "get",
          url: `${process.env.VUE_APP_LOCAL}/v1/cart`,
          headers: {
            Authorization: `Bearer ${JSON.parse(token).AUTH_TOKEN}`,
          },
        };
        await axios(config)
          .then((res) => {
            if (res.data.result) {
              commit("UPDATE_CART", res.data.result);
            } else {
              commit("UPDATE_CART", []);
            }
          })
          .catch((e) => console.log(e));
      } catch (error) {
        console.log("terjadi kesalahan server");
      }
    },

    async deleteCart({ dispatch }, id) {
      try {
        const token = localStorage.getItem("authenticate");
        var config = {
          method: "get",
          url: `${process.env.VUE_APP_LOCAL}/v1/cart/delete/${id}`,
          headers: {
            Authorization: `Bearer ${JSON.parse(token).AUTH_TOKEN}`,
          },
        };
        await axios(config)
          .then((res) => {
            dispatch("setCart");
          })
          .catch((e) => console.log(e));
      } catch (error) {
        console.log("terjadi kesalahan server");
      }
    },

    async deleteTransaksi({ dispatch }, id) {
      try {
        const token = localStorage.getItem("authenticate");
        var config = {
          method: "delete",
          url: `${process.env.VUE_APP_LOCAL}/v1/transaction/${id}`,
          headers: {
            Authorization: `Bearer ${JSON.parse(token).AUTH_TOKEN}`,
          },
        };
        await axios(config)
          .then((res) => {
            dispatch("setCart");
            // 1;

            dispatch("modTransaksi/setAllTransaction", null, { root: true });
            console.log(res.data)
          })
          .catch((e) => console.log(e));
      } catch (error) {
        console.log("terjadi kesalahan server");
      }
    },

    async checkout({ commit, dispatch }, data) {
      try {
        const token = localStorage.getItem("authenticate");
        var config = {
          method: "post",
          url: `${process.env.VUE_APP_LOCAL}/v1/checkout`,
          data: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${JSON.parse(token).AUTH_TOKEN}`,
          },
        };
        await axios(config)
          .then((res) => {
            dispatch("setCart");
          })
          .catch((e) => console.log(e));
      } catch (error) {
        console.log("terjadi kesalahan server");
      }
    },
  },
};