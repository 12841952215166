<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div> 

    <transition name="fade">
      <div class="z-30 fixed top-0 left-0 right-0 " v-show="showHeader">
        <Popover class="relative bg-primary border-b-4 border-secondary">
          <div class="max-w-7xl mx-auto  px-4 sm:px-6">
            <div class="flex justify-between items-center py-6 md:justify-between md:space-x-10">
              <div class="flex justify-start lg:w-0 lg:flex-1">
                <div @click="toggle">
                  <img class="h-8 w-auto sm:h-10" :src="logo" alt="" />
                </div>
              </div>
              <!-- hamburger menu  -->
              <div class="-mr-2 -my-2 md:hidden">
                <PopoverButton class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span class="sr-only">Open menu</span>
                  <MenuIcon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
              <!-- Large Screen  -->
              <PopoverGroup as="nav" class="hidden md:flex space-x-10">
                <router-link v-for="(menu, index) in menuUtama" :key="index" :to="{ name: menu.href }" class="text-base font-medium text-white hover:text-white"> {{ menu.name }} </router-link>
              </PopoverGroup>
              <div class="hidden lg:flex-1 items-center justify-end md:flex lg:w-0">
                <!-- searching  -->
                <box-pencarian :pencarian="pencarian"></box-pencarian>
                <!-- shopping cart  -->

                <shoping-cart></shoping-cart>
                <div v-if="!getAuthenticate"
                @click="login" class="px-4 py-1 bg-yellow-300 hover:cursor-pointer font-bold">Login</div>
                <Popover class="relative" v-slot="{ close }">
                  <!-- acatar  -->
                  <PopoverButton class="text-gray-500',  'ml-2 overflow-hidden w-10 h-10 items-center justify-center rounded-full text-base font-medium text-white" v-if="getAuthenticate">
                  <!-- <PopoverButton :class="[open ? 'text-gray-900' : 'text-gray-500',  'ml-2 overflow-hidden w-10 h-10 items-center justify-center rounded-full text-base font-medium text-white']" v-if="getAuthenticate"> -->
                    <img :src="getAuthenticate.avatar" alt="profile">
                  </PopoverButton>
                  <!-- logout button  -->
                  <transition
                    enter-active-class="transition ease-out duration-200"
                    enter-from-class="opacity-0 translate-y-1"
                    enter-to-class="opacity-100 translate-y-0"
                    leave-active-class="transition ease-in duration-150"
                    leave-from-class="opacity-100 translate-y-0"
                    leave-to-class="opacity-0 translate-y-1">
                    <PopoverPanel class="absolute z-30  mt-3 transform px-2 w-56 sm:px-0 ml-0 right-1 -translate-x-1">
                      <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div class="relative grid gap-6 bg-white px-5 py-3 sm:gap-4 sm:p-4">
                          <div class="w-full pl-4 pb-5 flex items-end hover:bg-gray-50 border-b-slate-100 border border-t-0 border-l-0 border-r-0 border-b-1">Halo, <span class="font-semibold mx-2">{{ getAuthenticate.profile.fullname }}</span>
                          </div>
                          <div v-for="item in menuProfile" :key="item.name"  v-on:click.prevent="goTo(item.href, close)" class="cursor-pointer -m-3 p-3 flex items-end rounded-lg hover:bg-gray-50" data-bs-dismiss="modal">
                            <!-- <component :is="item.icon" class="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" /> -->
                            <div class="ml-4">
                              <p class="text-base font-sm text-gray-900">
                                {{ item.name }}
                              </p>
                            </div>
                          </div>
                          <!-- logout  -->
                          <!-- <div class="pb-6 px-5 space-y-6 "> -->
                            <div @click="goToAuth(close)" class="cursor-pointer -m-3 p-3 flex items-end rounded-lg hover:bg-gray-50">
                              <div class="ml-4">
                                <p class="text-base font-sm text-gray-900">
                                  {{ getAuthenticate ? 'Logout' : 'Login' }}
                                </p>
                              </div>
                            </div>
                          <!-- </div> -->
                        </div>
                      </div>
                    </PopoverPanel>
                  </transition>
                </Popover>            
              </div>
            </div>
          </div>
          <transition
            enter-active-class="duration-200 ease-out"
            enter-from-class="opacity-0 scale-95"
            enter-to-class="opacity-100 scale-100"
            leave-active-class="duration-100 ease-in"
            leave-from-class="opacity-100 scale-100"
            leave-to-class="opacity-0 scale-95">
            <PopoverPanel v-slot="{ close }" focus class="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
              <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                <div class="pt-5 pb-6 px-5">
                  <div class="flex items-center justify-between">
                    <router-link :to="{ name: 'home' }"><img class="h-8 w-auto" :src="logo" alt="Workflow" /></router-link>
                    <div class="-mr-2">
                      <PopoverButton class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span class="sr-only">Close menu</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </PopoverButton>
                    </div>
                  </div>
                  <div class="mt-6">
                    <nav class="grid gap-y-8">
                      <div v-for="(item, index) in menuUtama" :key="index" @click="goTo(item.href, close)" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 hover:cursor-pointer">
                        <component :is="item.icon" class="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" />
                        <span class="ml-3 text-base font-medium text-gray-900">
                          {{ item.name }}
                        </span>
                      </div>
                      <a v-for="item in menuProfile" :key="item.name" v-on:click="goTo(item.href, close)" href="javascript:void(0)" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                        <component :is="item.icon" class="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" />
                        <span class="ml-3 text-base font-medium text-gray-900">
                          {{ item.name }}
                        </span>
                      </a> 
                    </nav>
                  </div>
                </div>
                <div class="py-6 px-5 space-y-6">
                  <!-- box pencarian  -->
                  <box-pencarian :pencarian="pencarian"></box-pencarian>
                </div>
                
                <div class="pb-6 px-5 space-y-6 ">
                  <div @click="goToAuth(close)" class="bg-orange-400 text-center w-full p-3 hover:cursor-pointer">
                    <span class="font-medium text-gray-900">
                      {{ getAuthenticate ? 'Logout' : 'Login' }}
                    </span>
                  </div>
                </div>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>
      </div>
    </transition>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import BoxPencarian from '../components/BoxPencarian.vue';
  import ShopingCart from '../components/ShopingCart.vue';
  import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue';
  import {
    MenuIcon,
    XIcon,
  } from '@heroicons/vue/outline';
    import Mixin from '@/mixin/index';
    
  export default {
    name: 'NavBar',
    mixins: [Mixin],
    components: {
      ShopingCart,
      BoxPencarian,
      Popover,
      PopoverButton,
      PopoverGroup,
      PopoverPanel,
      MenuIcon,
      XIcon,
    },
  data() {
    return {
      showHeader: false,
    };
  },
    computed: {
      ...mapGetters({
        getAuthenticate: 'getAuthenticate',
        getDark: 'getDark',
        menuUtama: 'modMenu/getMenuUtama',
        menuProfile: 'modMenu/getMenuProfile',
        logo: "logo"
      }),
    },
  mounted() {
    this.lastScrollPosition = window.pageYOffset;
    window.addEventListener("scroll", this.onScroll, { passive: true});
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, { passive: true});
  },
    methods: {
      goToAuth(close) {
        this.loader();
        if (this.getAuthenticate) {
          this.logout();
        } else {
          this.$router.push({ name: 'login' });
        }
        close();
      },
      toggle() {
        this.$router.push({ name: 'home' });
        // this.$store.dispatch('setDarkMode');
      },
      login() {
        this.loader();
        this.$router.push({ name: 'login' });
      },
      goTo(i, close) {
        // this.loader();
        this[i](i);
        close();
      },
      home(i) {
        this.$router.push({ name: i});
      },
      about(i) {
        this.$router.push({ name: i});
      },
      products(i) {
        this.$router.push({ name: i});
      },
      contact(i) {
        this.$router.push({ name: i});
      }, 
      profile() {
        this.$router.push({ name: 'profile' }); 
      },
      pencarian(val) {
        if (val) {
          this.loader();
          var params = {
              limit: 8,
              skip: 0,
              filter_product_name: val,
              sort: 'id DESC',
          };
        const typePencarian = {
            type: 'searching', 
            params: params
        }
          this.$store.dispatch('modProducts/setSearchProducts', typePencarian);
          this.$router.push({ name: 'products' })
        }
      },
      logout() {
        const requiresAuth = this.$route.meta.requiresAuth;
        this.$store.dispatch('logout', requiresAuth);
      },
      
    // Toggle if navigation is shown or hidden
    onScroll() { 
      if (window.pageYOffset < 0) {
        return;
      }
      if (
        Math.abs(window.pageYOffset - this.lastScrollPosition) <
        this.scrollOffset
      ) {
        return;
      } 
      if(window.pageYOffset > 300){
      this.showHeader = true;
      }else{
      this.showHeader = false;
      }
    //   this.showHeader = window.pageYOffset < this.lastScrollPosition;
      this.lastScrollPosition = window.pageYOffset;
    },
    },
  };
</script>
<style scoped> 

.fade-enter { 
  transform: translateY(-200);
  opacity: 1;
}
.fade-enter-active,
.fade-leave-active {
  /* transform-origin: top left 0; */
  transition: all 0.5s ease;
}
.fade-leave-from {
  transform: translateY(-200);
  opacity: 1;
}
.fade-leave-to {
  transform: translateY(-200px);
  opacity: 0;
}
</style>