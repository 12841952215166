<template>
  <div class="flex justify-center relative md:block mx-5" v-if="getCart.length > 0">
    <Popover class="relative" v-slot="{ open }">
      <PopoverButton
        :class="[open ? 'text-gray-900' : 'text-gray-500', 'flex ml-3 overflow-hidden w-10 h-10 items-center justify-center rounded-full text-base font-medium text-secondary ']">
        <a class="relative text-white transition-colors duration-200 transform
        dark:text-yellow-100 hover:text-yellow-100 dark:hover:text-gray-300" href="javascript:void(0)">
          <svg class="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 3H5L5.4 5M7 13H17L21 5H5.4M7 13L5.4 5M7 13L4.70711 15.2929C4.07714 15.9229 4.52331 17 5.41421 17H17M17 17C15.8954 17 15 17.8954 15 19C15 20.1046 15.8954 21 17 21C18.1046 21 19 20.1046 19 19C19 17.8954 18.1046 17 17 17ZM9 19C9 20.1046 8.10457 21 7 21C5.89543 21 5 20.1046 5 19C5 17.8954 5.89543 17 7 17C8.10457 17 9 17.8954 9 19Z"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <span class="absolute -top-1 -left-1 p-1 w-3 h-3 text-xs text-white
          bg-red-500 rounded-full"></span>
        </a>
      </PopoverButton>
      <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0
        translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in
        duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0
        translate-y-1">
        <!-- v-slot="{ close }" -->
        <PopoverPanel v-slot="{ close }" class="absolute z-30  mt-3 transform px-2 w-56 sm:px-0 ml-0 right-1 bg-white
          -translate-x-1">
          <div class="shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden p-4">
            <div class="relative grid gap-6 bg-white px-5 py-3 sm:gap-4 sm:p-4">
              <a v-for="item in getCart" :key="item.id" v-on:click="async () => {
                await viewProduct(item.product_name, item.sub_category, item.slug)
                }" href="javascript:void(0)" class="-m-3 p-3 items-end rounded-lg hover:bg-gray-50">
                <!-- <component :is="item.icon" class="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" /> -->
                <div class="grid grid-cols-12">
                  <div class="col-span-3 items-center">
                    <img class="items-center" :src="item.image ? item.image : gambar" alt="{{ item.product_name }}" />
                  </div>
                  <div class="col-span-9 ml-2">
                    <p class="text-[14px] font-semibold text-gray-900 leading-4">
                      {{ item.product_name }}
                    </p>
                    <p class="text-[12px] text-gray-500">
                      {{ formatRupiah(item.price) }}
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div @click.prevent="checkout(close)" class="w-full hover:bg-orange-600 duration-300 ease-in-out
              bg-orange-400 py-2 px-4 text-center hover:cursor-pointer group">
              <span class="font-bold text-sm text-zinc-800 group-hover:text-white">Checkout</span>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
  <div class="flex justify-center relative md:block mx-5" v-else>
    <span
      class="relative text-white transition-colors duration-200 transform dark:text-yellow-100 hover:text-yellow-100 dark:hover:text-gray-300"
      href="javascript:void(0)">
      <svg class="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3 3H5L5.4 5M7 13H17L21 5H5.4M7 13L5.4 5M7 13L4.70711 15.2929C4.07714 15.9229 4.52331 17 5.41421 17H17M17 17C15.8954 17 15 17.8954 15 19C15 20.1046 15.8954 21 17 21C18.1046 21 19 20.1046 19 19C19 17.8954 18.1046 17 17 17ZM9 19C9 20.1046 8.10457 21 7 21C5.89543 21 5 20.1046 5 19C5 17.8954 5.89543 17 7 17C8.10457 17 9 17.8954 9 19Z"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </span>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue';

  export default {
      name: 'ShopingCart',
      components: {
      Popover,
      PopoverButton,
      PopoverGroup,
      PopoverPanel,
    },
    methods: {
      viewProduct(product_name, category, slug) {
        const qryProduct = {
          filter_product_name: product_name,
        }
        this.$store.dispatch('setDetailProduct', qryProduct);
        // this.$store.dispatch('setProductSearch', category);
        this.$router.push({ name: 'productDetail', params:{ slug: slug} });
      },
      checkout(close) {
        close();
        this.$router.push({ name: 'checkout' });
      },
      formatRupiah(val) {
        const format = val.toString().split('').reverse().join('');
        const convert = format.match(/\d{1,3}/g);
        const rupiah = 'Rp ' + convert.join('.').split('').reverse().join('');
        return rupiah;
      },
    },
    computed: {
      ...mapGetters({
        getCart: 'modCart/getCart',
        getDark: 'getDark',
      }), 
    },
  };
</script>