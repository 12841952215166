<template>
    <div v-if="getAuthenticate" class="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div class="flex flex-wrap lg:justify-center justify-between items-start">
            <!-- left -->
            <div id="summary" class="g:flex-1 w-full xl:w-1/4 lg:w-2/5 px-4 py-10">
                <div class="w-full">
                    <div :class="getDark ? 'bg-zinc-800 border-zinc-800':'border-gray-300'" class="p-4 border -mb-1">
                        <div class="flex-row flex justify-start items-center w-full"> 
                            <div class="flex-shrink-0 mr-3">
                                <a href="#" class="block relative">
                                    <img alt="profil" :src="getAuthenticate.avatar"
                                        class="mx-auto object-cover rounded-full h-16 w-16 " />
                                </a>
                            </div>
                            <div class=" flex flex-col">
                                <span :class="getDark ? 'text-zinc-400':'text-zinc-800'" class="text-lg font-medium">
                                    {{ getAuthenticate.profile.fullname }}
                                </span>
                                <span :class="getDark ? 'text-zinc-500':'text-zinc-400'" class="text-xs">
                                    {{ getAuthenticate.profile.email }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col sm:flex-row sm:justify-around">
                        <div class="w-full mb-20">
                            <nav class="">
                                <div v-for="(item, i) in getMenuAuth" :key="i" @click="linkTo(item.link)"
                                    :class="[item.active ? 
                                    getDark ? 'bg-zinc-400 border-zinc-400':'text-black bg-zinc-300' : 
                                    getDark ? 'bg-zinc-700 hover:bg-zinc-600 text-white border-zinc-800':'text-black bg-white hover:bg-zinc-100']" class="hover:cursor-pointer flex items-center p-3
                                transition-colors duration-200
                                border -mb-1">
                                    <i :class="`fa-solid ${item.icon}`"></i>
                                    <span class="mx-4 text-[14px] font-semibold">
                                        {{ item.name }}
                                    </span>
                                    <span v-if="item.total > 0" class="flex-grow text-right">
                                        <button type="button"
                                            class="w-6 h-6 text-xs  rounded-full text-white bg-red-500">
                                            <span class="p-1">
                                                {{ item.total }}
                                            </span>
                                        </button>
                                    </span>
                                </div>
                                <div @click="logout()"
                                    :class="getDark ? 'bg-zinc-700 hover:bg-zinc-600 text-white border-zinc-800':'text-black bg-white hover:bg-zinc-100'"
                                    class="flex items-center p-3 transition-colors duration-200 border hover:cursor-pointer -mb-1">
                                    <i class="fa-solid fa-right-from-bracket"></i>
                                    <span class="mx-4 text-[14px] font-normal">
                                        Logout
                                    </span>
                                    <span class="flex-grow text-right">
                                    </span>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <!-- right  -->
            <div class="lg:flex-1 w-full xl:w-3/4 lg:w-3/4  px-2 mt-6">
                <router-view v-slot="{ Component }">
                    <!-- <transition> -->
                    <component :is="Component" />
                    <!-- </transition> -->
                </router-view>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
const menus = [
    {
        name: 'My Account',
        active: true,
        link: 'profile',
        icon: 'fa-address-card',
        total: 0,
    },
    {
        name: 'Transaction',
        active: false,
        link: 'transaction',
        icon: 'fa-list-check',
        total: 0,
    },
    {
        name: 'Order',
        active: false,
        link: 'order',
        icon: 'fa-basket-shopping',
        total: 0,
    },
    //   {
    //     name: 'Invoice',
    //     active: false,
    //     link: 'invoice',
    //     icon: 'fa-basket-shopping',
    //     total: 0,
    //   },
]
export default {
    name: 'Default',
    data() {
        return {
            menus,
            totWaiting: 0,
            totOrder: 0,
            // getAuthenticate: null,
        }
    },
    // beforeRouteEnter(to, from, next) {
    //     next(vm => {
    //         vm.$store.dispatch('modTransaksi/setAllTransaction'); 
    //         return;
    //     })
    // },
    // beforeMount() {
    //     this.totWaitPay = localStorage.getItem('totWaitPay');
    //     console.log(localStorage.getItem('totWaitPay'))
    //     this.totOrder = localStorage.getItem('totOrder');
    // }, 
    methods: {
        linkTo(val) {
            this.$store.dispatch('modMenu/setAktifMenu', val);
            for (let i = 0; i < this.getMenuAuth.length; i++) {
                if (this.getMenuAuth[i].link === val) {
                    // this.getMenuAuth[i].active = true;
                    this.$router.push({ name: val });
                } else {
                    // this.getMenuAuth[i].active = false;
                }
            }
        },
        logout() {
            const requiresAuth = this.$route.meta.requiresAuth;
            this.$store.dispatch('logout', requiresAuth);
        },
        // updateAuth() {
        //     this.getAuthenticate = this.setAuth
        // }
    },
    async mounted() {

        this.$store.dispatch('modMenu/setAktifMenu', this.$route.name);
        // for (let i = 0; i < this.getMenuAuth.length; i++) { 
        //     if (this.getMenuAuth[i].link === this.$route.name) {
        //         this.getMenuAuth[i].active = true;
        //     }else {
        //             this.getMenuAuth[i].active = false;
        //         }
        //     // if (this.getMenuAuth[i].link === 'transaction') {
        //     //     this.getMenuAuth[i].total = this.getTotalWaitingTransaction;
        //     // }
        //     // if (this.getMenuAuth[i].link === 'order') {
        //     //     this.getMenuAuth[i].total = localStorage.getItem('totOrder');
        //     // }
        // }
        // console.log(this.setAuth)
        // this.getAuthenticate = await this.setAuth;
    },
    created() {
        const params = {
            limit: 5,
            skip: 0,
            filter_product_name: '',
            filter_tahun: '',
            filter_category: '',
            filter_status: 'Waiting Payment',
            sort: 'created_at DESC',
        };
        this.$store.dispatch('modMenu/setAktifMenu', this.$route.name);
        this.$store.dispatch('modTransaksi/setAllTransaction', 0);
    },

    watch: {
        // setAuth(newCount, oldCount) {
        //     if (newCount) {
        //         this.updateAuth();
        //     } 
        // },
    },

    computed: {
        ...mapGetters({
            getTotalWaitingTransaction: 'modTransaksi/getTotalWaitingTransaction',
            getMenuAuth: 'modMenu/getMenuAuth',
            getDark: 'getDark',
        }),
        getAuthenticate() {
            return this.$store.getters['getAuthenticate']
        }
    },
};
</script>
<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
