<template>
    <form @submit.prevent="cari(dataPencarian)" class="block md:hidden lg:block">
        <div class="font-sans text-black ">
            <div class="border-0 overflow-hidden flex">
                <input type="text" class="focus:outline-none border border-x-zinc-300 bg-white px-4 py-1 w-11/12" placeholder="Search..." v-model="dataPencarian">
                <button class="flex items-center justify-center px-4 bg-red-500 text-white font-bold">
                    <svg class="h-4 w-4 text-grey-dark" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"/></svg>
                </button>
            </div>
        </div>
    </form>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name:'BoxPencarian',
    props:{
        pencarian: Function
    },
    data(){
        return{
            dataPencarian:''
        }
    },
    methods:{
        cari(){
            this.pencarian(this.dataPencarian);
            this.dataPencarian=''
        }
    },
    computed: {
      ...mapGetters({
        getDark: 'getDark',
      }),
    },
}
</script>
<style>
    
</style>